import styled from 'styled-components';
import iconWhats1 from '../../assets/icon/IconWhats.svg';
import iconWhats2 from '../../assets/icon/IconWhats2.svg';

export const Container = styled.header`
  z-index: 99;
  width: 70%;
  background-color: #fff;
  height: auto;
  position: absolute;
  display: grid;
  grid-template-columns: 18% 64% 18%;
  justify-content: space-between;
  top: 0;
  margin: 0 15%;
  .logo {
    margin: 0 25%;
    display: flex;
    justify-content: center;
    align-content: center;

    @media screen and (max-width: 900px) {
      margin: 0 10%;
      padding: 10px 0;
    }
    .hamburger-react {
      display: none;
      @media screen and (max-width: 900px) {
        display: inherit;
      }
    }
    a {
      display: flex;
      width: 100%;
      margin: auto;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 900px) {
          height: 50px;
          width: auto;
        }
      }
    }
  }
  .midHeader {
    .contato {
      margin-top: 3.5%;
      display: flex;
      justify-content: space-evenly;
      .tel {
        margin: auto;
        display: flex;
        .FiPhone {
          stroke: #f89000;
          transform: scale(1.4);
          margin-right: 5px;
        }
        span {
          color: #4b545f;
          margin-right: 5px;
          margin-left: 5px;
        }
        a {
          justify-content: space-evenly;
          margin: auto;
          display: flex;
          color: #575757;
          opacity: 1;
          text-align: left;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0px;
          font-weight: bold;
        }
      }
      .line {
        width: 3px;
        border-radius: 40px;
        height: 20px;
        background-color: #f89000;
      }
      .mail {
        justify-content: space-evenly;
        margin: auto;
        display: flex;
        a {
          color: #575757;
          opacity: 1;
          text-align: left;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0px;
          font-weight: bold;
        }
        .GoMail {
          transform: scale(1.5);
          margin-right: 10px;

          path {
            fill: #f89000;
          }
        }
      }
      @media (min-width: 900px) and (max-width: 1160px) {
        display: none;
      }
    }
    nav {
      ul {
        margin-top: 3.5%;
        display: flex;
        justify-content: space-between;
        li {
          display: table-cell;
          list-style: none;
          margin: auto;
          a {
            color: #575757;
            opacity: 1;
            text-align: left;
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0px;
            display: inline-block;
            padding: 3px 5px;
            position: relative;
            @media screen and (max-width: 1240px) {
              font-size: 13px;
            }
          }
          a:after {
            content: '';
            display: flex;
            height: 3px;
            border-radius: 15px;
            left: 50%;
            position: absolute;
            background: #f89000;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }
          a:hover:after {
            width: 100%;
            left: 0;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      position: absolute;
      width: 100%;
      transition: all ease-in 0.3s;
      transform: translateY(-100%);
      .contato {
        display: none;
      }
      nav {
        ul {
          display: inherit;
          display: flex;
          flex-direction: column;
          background: #fff;
          li {
            a {
              font-size: 20px;
              padding: 15px;
            }
          }
        }
      }
      &.mobile {
        transform: translateY(0);
        margin-top: 70px;
      }
    }
  }
  a {
    .Whats {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 100%;
      background-color: #4caf50;
      z-index: -1;
      transition: all linear 0.3s;
      background-image: url(${iconWhats1});
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }
  .Whats:hover {
    background-color: #004291;
    background-image: url(${iconWhats2});
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0;
    grid-template-columns: 100%;
    border-bottom: 2px solid #f5f5f5;
  }
`;
