import styled from 'styled-components';

export const Video = styled.div`
  @media only screen and (max-width: 480px) {
    height: auto;
    padding-top: 5%;
  }
  background-color: #f5f5f5;
  position: relative;
  width: 100%;
  height: fit-content;
  .Header {
    @media only screen and (max-width: 768px) {
      padding: 5% 5%;
    }
    padding-left: 15%;
    padding-top: 10%;
    .Title {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
        max-width: none;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 58px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      max-width: 55%;
      line-height: 90%;
      margin: 0;
    }
    .Line {
      margin: 20px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
    .subTitle {
      color: #575757;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 15px;
      letter-spacing: 0px;
      position: relative;
    }
  }
  .bgBlue {
    @media only screen and (max-width: 768px) {
      margin-top: 0;
      height: 450px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: -25px;
      height: 200px;
    }

    position: absolute;
    height: 500px;
    right: 0;
    width: 40%;
    background-color: #004291;
    margin-top: -100px;
  }
  .bgOrange {
    @media only screen and (max-width: 480px) {
      height: 170px;
      margin-top: 100px;
    }
    margin-top: 150px;
    position: absolute;
    height: 450px;
    left: 0;
    background-color: #f89000;
    width: 60%;
  }
  .iFrame {
    display: flex;
    position: relative;
    justify-content: center;
    align-content: center;
    padding-bottom: 150px;
    video {
      @media only screen and (max-width: 768px) {
        width: 90%;
      }
      @media only screen and (max-width: 480px) {
        width: 90%;
      }
      height: auto;
      width: 50%;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 8%;
    }
    @media only screen and (max-width: 480px) {
      margin-top: unset;
      padding: unset;
    }
  }
`;
