import styled from 'styled-components';
import banner from '../../assets/img/bgLancamento.png';

export const Lista = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 90px;
  }
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10% 0 0 0;
  .banner {
    position: absolute;
    width: 100%;
    height: 50vh;
    background: #f89000;
    top: 0;
    left: 0;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(248, 144, 0, 0.68);
      background: url(${banner});
      opacity: 0.1;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
    }
  }
  .content {
    position: relative;
    .Text {
      @media only screen and (max-width: 768px) {
        padding: 5%;
      }
      padding-left: 15%;
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
      }
      .Line {
        margin: 20px 0;
        width: 80px;
        height: 10px;
        background-color: #004291;
        border-radius: 100px;
      }
      .footerList {
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 22px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 90%;
        margin: 0;
      }
    }
    .tabelaCards {
      @media only screen and (max-width: 1024px) {
        grid-template-columns: 50% 50%;
        grid-gap: 10px;
      }
      @media only screen and (max-width: 768px) {
        grid-template-columns: 49% 49%;
        grid-gap: 10px;
        height: auto;
        width: 90%;
        margin: auto;
        grid-gap: 10px;
      }
      @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        margin: 10px;
        top: unset;
        grid-gap: 10px;
        margin: auto;
      }

      top: -25px;
      position: relative;
      z-index: 1;
      background-color: #fff;
      margin: 5% 15%;
      padding: 2.5%;
      display: grid;
      grid-template-columns: 32% 32% 32%;
      grid-gap: 1%;
      justify-content: center;
      .card {
        @media only screen and (max-width: 768px) {
          padding: 5%;
          height: auto;
        }
        justify-content: center;
        align-content: center;
        margin: auto;
        display: grid;
        grid-template-rows: 150px 250px;
        grid-row-gap: 5px;
        height: fit-content;
        width: 100%;
        background-color: #ededed;
        a {
          .boxImg {
            padding: 2%;
            display: flex;
            justify-content: center;
            align-content: center;
            margin: auto;
            width: 100%;
            height: 100%;
            transition: transform 0.5s;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          .boxImg:hover {
            transform: scale(1.1);
          }
        }
        .boxInfo {
          .logo {
            padding: 10px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 45%;

            a {
              display: flex;
              height: 71px;
            }
            img {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
          .info {
            display: grid;
            grid-template-columns: 45% 45%;
            grid-gap: 5px;
            justify-content: center;
            align-content: center;
            .first {
              background-color: #f89000;
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              letter-spacing: 0px;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
              margin: 0;
              padding: 5%;
              text-align: center;
            }
            .second {
              background-color: #ff0d58;
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              letter-spacing: 0px;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
              margin: 0;
              padding: 5%;
              text-align: center;
            }
            .third {
              background-color: #51a31d;
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              letter-spacing: 0px;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
              margin: 0;
              padding: 5%;
              text-align: center;
            }
            .fourth {
              background-color: #0f9191;
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              letter-spacing: 0px;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
              margin: 0;
              padding: 5%;
              text-align: center;
            }
          }
          .buttonMore {
            width: 90%;
            height: 35px;
            border-radius: 100px;
            background-color: #004291;
            display: flex;
            margin: 5%;
            transition: all linear 0.3s;

            a {
              color: #fff;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              text-decoration: none;
              display: flex;
              width: 100%;
              height: 100%;
              margin: auto;
            }
          }
          .buttonMore:hover {
            background-color: #f89000;
          }
        }
      }
      .esconder {
        display: none;
      }
      .Loader {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
`;
