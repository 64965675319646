import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Lancamentos from '../pages/Lancamentos';
import Sobre from '../pages/Sobre';
import Contato from '../pages/Contato';
import Imoveis from '../pages/Imoveis';
import Busca from '../pages/Busca';
import Post from '../pages/Blog/Post';
import Blog from '../pages/Blog';
import Entregues from '../pages/Entregues';
import TrabalheConosco from '../pages/TrabalheConosco';
import Empreendimentos from '../pages/Empreendimentos';

import Login from '../pages/AreaDoCliente/Login';
import Inicio from '../pages/AreaDoCliente/Inicio';
// import Licitacoes from '../pages/AreaDoCliente/Licitacoes';
// import MeusDados from '../pages/AreaDoCliente/MeusDados';

import PrivateRoute from '../components/PrivateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/lancamentos" exact component={Lancamentos} />
      <Route path="/quem-somos" exact component={Sobre} />
      <Route path="/contato" exact component={Contato} />
      <Route path="/trabalhe-conosco" exact component={TrabalheConosco} />
      <Route path="/imoveis" exact component={Imoveis} />
      <Route path="/busca" exact component={Busca} />
      <Route path="/entregues" exact component={Entregues} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/post/:slug" exact component={Post} />

      <Route path="/empreendimentos/:slug" exact component={Empreendimentos} />

      {/* ROTAS ÁREA RESTRITA */}
      <Route path="/area-do-cliente/login" exact component={Login} />
      <PrivateRoute path="/area-do-cliente" exact component={Inicio} />
      
      {/* <PrivateRoute path="/area-do-cliente" exact component={Inicio} /> */}
      {/* <PrivateRoute path="/area-do-cliente/licitacoes/:slug" exact component={Licitacoes} />
      <PrivateRoute path="/area-do-cliente/licitacoes" exact component={Licitacoes} />
      <PrivateRoute path="/area-do-cliente/meus-dados" exact component={MeusDados} /> */}
    </Switch>
  );
}
