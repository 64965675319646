import styled from 'styled-components';

export const Banner = styled.div`
  @media only screen and (max-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (max-width: 768px) {
    height: 50vh;
  }
  @media only screen and (max-width: 480px) {
    height: 50vh;
    margin-top: 70px;
  }
  height: 85vh;
  width: 100%;

  .slick-slide,
  .ant-carousel,
  .slick-slider,
  .slick-track,
  .slick-list,
  .slick-active,
  .slick-current {
    height: 100%;
    width: 100%;
  }

  > .slick-list {
    height: 100%;
    width: 100%;
  }
  .slick-slide {
    > div {
      height: 100%;
    }
  }

  .slick-dots-bottom,
  .slick-dots {
    right: 5%;
    bottom: 13%;
    left: unset;
    > li {
      width: 25px;
      > button {
        background-color: #004291;
        opacity: 1;
        border-radius: 30px;
        height: 5px;
      }
    }
  }
  .ant-carousel {
    .slick-slider,
    .slick-initialized {
      .slick-dots,
      .slick-dots-bottom {
        .slick-active {
          button {
            background: #f89000;
            opacity: 1;
            border-radius: 30px;
          }
        }
      }
    }
  }

  .slider {
    height: 100%;
    width: 100%;
    position: relative;
    margin: auto;
    display: flex;
    .Loader {
      margin-top: 40vh;
      justify-content: center;
      align-items: center;
      display: flex;
      @media only screen and (max-width: 1100px){
        margin-top: 150px;
      }
    }
    .text {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin: 0 0 0 5%;
      }
      @media only screen and (max-width: 480px) {
        width: 70%;
        font-size: 30px;
      }
      width: 100%;
      position: absolute;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      font-size: 40px;
      letter-spacing: 0px;
      margin: 25% 0 0 15%;
      span {
        @media only screen and (max-width: 480px) {
          font-size: 30px;
        }
        width: 15%;
        padding-left: 5%;
        padding-right: 1%;
        margin: 0;
        color: #fff;
        background-color: #f89000;
        text-decoration: none;
        font-size: 40px;
        letter-spacing: 0px;
      }
    }
    .bg {
      @media only screen and (max-width: 480px) {
        width: 100%;
        height: 100%;
      }
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const Busca = styled.div`
  @media only screen and (max-width: 480px) {
    border-radius: 0;
    width: 100%;
    flex-direction: column;
    padding: 20px 5%;
    height: auto;
  }
  z-index: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  width: 70%;
  height: 100px;
  background-color: #004291;
  border-radius: 100px;
  top: -50px;
  .Text {
    @media only screen and (max-width: 480px) {
      padding-bottom: 20px;
    }
    @media only screen and (max-width: 768px) {
      margin-left: 2%;
    }
    align-items: center;
    justify-content: center;
    margin: 0 0 0 2%;
    p {
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      color: #fff;
      opacity: 1;
      text-align: left;
      text-decoration: none;
      font-size: 24px;
      letter-spacing: 0px;
      font-weight: bold;
      margin: 0;
    }
    .Line {
      width: 45px;
      height: 4px;
      background-color: #f89000;
      border-radius: 40px;
    }
  }
  .Selects {
    width: 70%;
    form {
      display: grid;
      align-items: center;
      justify-content: space-evenly;
      grid-template-columns: 25% 25% 25% 15%;
      width: 100%;

      @media screen and (max-width: 480px) {
        grid-template-columns: 100%;
        grid-gap: 10px;
      }

      .ant-select {
        justify-content: center;
        width: 100%;
        display: flex;
        margin: auto;
        background-color: #fff;
        border-radius: 65px;
      }
      .ant-select-selector {
        display: flex;
        width: 100%;
        height: 30px;
        background-color: #eff1f5;
        border-radius: 65px;
        border: 1px solid #e1e4eb;
      }
      .ant-select-selection-placeholder {
        font-size: 14px;
        color: #575757;
        font-weight: 400;
        margin: auto;
        margin-left: 5px;
      }
      .ant-select-arrow {
        color: #f89000;
        font-size: 14px;
      }
      button {
        display: flex;
        padding: 5px 0;
        width: 100%;
        cursor: pointer;
        text-align: center;
        background-color: #f89000;
        text-decoration: none;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
        border: none;
        transition: all ease 0.5s;
        p {
          font-size: 15px;
          letter-spacing: 0px;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
        }
      }
      button:hover {
        background-color: #ff741d;
      }
      button:active {
        background-color: #ff741d;
        box-shadow: 0 5px #666;
        transform: translateY(4px);
      }
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const Vantagens = styled.div`
  @media only screen and (max-width: 768px) {
    padding-bottom: 5%;
  }
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 0 0 20% 0;
  .text {
    @media only screen and (max-width: 480px) {
      padding: 5% 0 5% 15%;
    }
    padding-left: 15%;
    padding-top: 5%;
    .Top {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 55px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      line-height: 90%;
      margin: 0;
    }
    .boxOrange {
      @media only screen and (max-width: 1024px) {
        width: 27%;
      }
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      @media only screen and (max-width: 480px) {
        width: 60%;
        margin-top: 2%;
        margin-left: 27%;
      }
      position: relative;
      width: 25%;
      margin-top: 1%;
      background-color: #f89000;
      margin-left: 13%;
      z-index: 5;
      .Bottom {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 55px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        line-height: 130%;
        margin: 0 0 0 45%;
      }
    }
  }
  .boxBanner {
    @media only screen and (max-width: 768px) {
      display: none;
    }
    margin-top: -5px;
    position: relative;
    z-index: 1;
    width: 50%;
    margin-left: 5%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .boxBlue {
    @media only screen and (max-width: 768px) {
      display: none;
    }
    position: absolute;
    height: 170px;
    right: 0;
    width: 55%;
    background-color: #004291;
    margin-top: -150px;
  }
  .boxVantagens {
    @media only screen and (max-width: 1024px) {
      margin: -180px 0 0 35%;
      padding-left: 13%;
    }
    @media only screen and (max-width: 768px) {
      padding: unset;
      margin: auto;
      width: 100%;
    }
    @media only screen and (max-width: 480px) {
      margin: auto;
      width: 100%;
      justify-content: space-between;
      padding: 20px 5%;
      grid-template-columns: 40% 40%;
      height: auto;
    }

    position: relative;
    z-index: 2;
    width: 50%;
    background-color: #fff;
    height: 225px;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 17% 17% 17% 17%;
    padding-left: 10%;
    justify-content: space-evenly;
    margin: -220px 0 0 35%;
    border-bottom: 5px solid #f89000;
    .firstCollum {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
      p {
        margin-top: 5%;
        text-align: center;
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .secondCollum {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 40%;
      }
      p {
        margin-top: 5%;
        text-align: center;
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .thirdCollum {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
      p {
        margin-top: 5%;
        text-align: center;
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .fourthCollum {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
      p {
        margin-top: 5%;
        text-align: center;
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
  }
`;

export const Lista = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  padding: 10% 0;
  .Text {
    @media only screen and (max-width: 768px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      line-height: 90%;
      margin: 0;
    }
    .Line {
      margin: 20px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
    .footerList {
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
        margin-right: 5%;
      }
      color: #575757;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 0px;
      position: relative;
      font-weight: 400;
      line-height: 90%;
      margin: 0;
    }
  }

  .tabelaCards {
    @media only screen and (max-width: 1024px) {
      grid-template-columns: 50% 50%;
      grid-gap: 10px;
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 49% 49%;
      grid-gap: 10px;
      height: auto;
      margin: 5%;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      margin: 10px;
      top: unset;
      grid-gap: 10px;
    }
    top: -25px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    margin: 5% 15%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
    justify-content: center;
    .card {
      @media only screen and (max-width: 768px) {
        padding: 5%;
        height: auto;
      }
      justify-content: center;
      align-content: center;
      margin: auto;
      display: grid;
      grid-template-rows: 150px 250px;
      grid-row-gap: 5px;
      height: fit-content;
      width: 100%;
      background-color: #ededed;
      a {
        .boxImg {
          padding: 2%;
          display: flex;
          justify-content: center;
          align-content: center;
          margin: auto;
          width: 100%;
          height: 100%;
          transition: transform 0.5s;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .boxImg:hover {
          transform: scale(1.1);
        }
      }
      .boxInfo {
        .logo {
          padding: 10px;
          margin: auto;
          display: flex;
          justify-content: center;
          align-content: center;
          width: 45%;

          a {
            display: flex;
            height: 71px;
          }
          img {
            object-fit: contain;
            width: 100%;
            height: auto;
          }
        }
        .info {
          display: grid;
          grid-template-columns: 45% 45%;
          grid-gap: 5px;
          justify-content: center;
          align-content: center;
          .first {
            height: 50px;
            background-color: #f89000;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0;
            padding: 5%;
            text-align: center;
          }
          .second {
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
            height: 50px;
            background-color: #ff0d58;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0;
            padding: 5%;
            text-align: center;
          }
          .third {
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
            height: 50px;
            background-color: #51a31d;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0;
            padding: 5%;
            text-align: center;
          }
          .fourth {
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
            height: 50px;
            background-color: #0f9191;
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            font-size: 14px;
            letter-spacing: 0px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0;
            padding: 5%;
            text-align: center;
          }
        }
        .buttonMore {
          width: 90%;
          height: 35px;
          border-radius: 100px;
          background-color: #004291;
          display: flex;
          margin: 5%;
          transition: all linear 0.3s;

          a {
            color: #fff;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            text-decoration: none;
            display: flex;
            width: 100%;
            height: 100%;
            margin: auto;
          }
        }
        .buttonMore:hover {
          background-color: #f89000;
        }
      }
    }
    .esconder {
      display: none;
    }
  }
  .Loader {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
    height: 500px;
    @media screen and (max-width: 500px) {
      height: 250px;
    }
  }
`;
export const Depoimentos = styled.div`
  padding-top: 10%;
  .Text {
    padding-left: 15%;
    max-width: 55%;
    .headerList {
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      margin: 0;
      line-height: 100%;
      @media only screen and (max-width: 768px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 36px;
        padding-right: 10%;
      }
    }
    .Line {
      margin: 20px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
    @media only screen and (max-width: 768px) {
      max-width: 70%;
      padding-left: 5%;
    }
    @media only screen and (max-width: 480px) {
      max-width: none;
      padding-left: 5%;
    }
  }
  .tableDepoimentos {
    margin: 5% 15%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
    justify-content: center;
    .boxDepoimentos {
      width: 100%;
      height: 370px;
      background-color: #fff;
      .boxImg {
        @media only screen and (max-width: 768px) {
          margin: -50px auto 0 auto;
          width: 100px;
          height: 100px;
        }
        height: 100px;
        width: 100px;
        display: flex;
        margin: -50px 35% 0 35%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-width: 3px;
          border-style: solid;
          border-color: #f6a83c;
          border-radius: 100px;
        }
      }
      .title {
        font-family: 'Sora', sans-serif;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 22px;
        letter-spacing: 0px;
        color: #004291;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
        margin-left: 10%;
        margin-top: 20px;
      }
      .subTitle {
        font-family: 'Sora', sans-serif;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        color: #313131;
        position: relative;
        font-weight: 400;
        margin: 0;
        line-height: 100%;
        margin-left: 10%;
      }
      .texto {
        padding: 5% 5% 15% 5%;
        p {
          font-family: 'Sora', sans-serif;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          color: #313131;
          position: relative;
          font-weight: 400;
          margin: 0;
          margin-left: 5%;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-row-gap: 75px;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 5%;
      margin: 5% 5%;
      grid-row-gap: 75px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 90px;
    padding-bottom: unset;
    padding-top: unset;
  }
  .Loader {
      justify-content: center;
      align-items: center;
      display: flex;
      margin: auto;
      height: 500px;
      @media screen and (max-width: 500px) {
        height: 250px;
    }
  }
`;
export const Blog = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  padding: 10% 0;
  .Text {
    @media only screen and (max-width: 768px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      line-height: 90%;
      margin: 0;
    }
    .Line {
      margin: 20px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
    .footerList {
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 22px;
        margin-right: 5%;
      }
      color: #575757;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 0px;
      position: relative;
      font-weight: 400;
      line-height: 90%;
      margin: 0;
    }
  }

  .tabelaCards {
    position: relative;
    z-index: 1;
    background-color: #fff;
    margin: 5% 15%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
    justify-content: center;
    .boxBlog {
      width: 100%;
      .headerBox {
        .avatar {
          width: 100%;
          padding: 10px;
          height: 200px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .titleBlog {
          color: #004291;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 22px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          line-height: 100%;
          margin: 0;
          padding: 10px;
          height: 90px;
        }
        .content {
          color: #313131;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 10px;
          height: 250px;
        }
      }
      .buttonMore {
        width: 90%;
        height: 35px;
        border-radius: 100px;
        background-color: #004291;
        display: flex;
        margin: 5%;
        transition: all linear 0.3s;
        a {
          color: #fff;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-decoration: none;
          display: flex;
          width: 100%;
          height: 100%;
          margin: auto;
        }
      }
      .buttonMore:hover {
        background-color: #f89000;
      }
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-row-gap: 75px;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 5%;
      margin: 5% 5%;
      grid-row-gap: 75px;
    }
  }
  .Blog {
    text-decoration: none;
    width: 20%;
    height: auto;
    border-radius: 100px;
    background-color: #f89000;
    display: flex;
    transition: all linear 0.3s;
    margin: auto;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    font-size: 26px;
    padding: 5px 5px;
    :hover {
      background-color: #ff741d;
    }
    @media only screen and (max-width: 800px) {
      width: 40%;
    }
    @media only screen and (max-width: 480px) {
      width: 60%;
    }
  }
  .Loader {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 25px auto;
    height: 600px;
    @media screen and (max-width: 500px) {
      height: 300px;
    }
  }
`;
