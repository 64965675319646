import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Progress } from 'antd';
import Loader from 'react-loader-spinner';
import { notification } from 'antd';
import ReactHtmlParser from 'react-html-parser';

// components
import HeaderAreaDoCliente from '../../../components/HeaderAreaDoCliente';
import Footer from '../../../components/Footer';

// style
import { Lista, Formulario } from './styles';

// api
import api from '../../../services/api';

// form
import InputMask from 'react-input-mask';

function Inicio() {
  const [relatorio, setRelatorio] = useState();
  // const [colunas, setColunas] = useState();
  // const [dataFormatada, setDataFormatada] = useState();
  const [loading, setLoading] = useState(true);
  
  const [jaAcessouUmaVez, setjaAcessouUmaVez] = useState();
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState('');

  const [empreendimentoNome, setEmpreendimentoNome] = useState('');
  const [empreendimentoBloco, setEmpreendimentoBloco] = useState('');

  // handleSubmit
  async function handleSubmit(e) {
    e.preventDefault();

    const loginData = new FormData();
    loginData.append('username', email);
    loginData.append('telefone', telefone);

    let axiosConfig = {
      headers: {
        'Content-Type': 'multipart/formdata',
      },
    };

    try {
      await api
        .post('/jwt-auth/v1/token', loginData, axiosConfig)
        .then((res) => {
   
        });
    } catch (err) {

      console.log( err.response.data );

      notification.success({
        message: 'Dados confirmados com sucesso!',
        placement: 'bottomRight',
      });

      setjaAcessouUmaVez(true);
      localStorage.setItem('ja_acessou_uma_vez', "true");
      window.location.reload();
    }
  }

  useEffect(() => {
    setLoading(true);

    // Se for primeiro acesso
    if (localStorage.getItem('ja_acessou_uma_vez') === "false" ) { 
      setjaAcessouUmaVez(false);
      setEmail(localStorage.getItem('email'));
      setTelefone(localStorage.getItem('telefone'));
      console.log( 'false' );
    } else {
      setjaAcessouUmaVez(true);
      console.log( 'true' );
    }

    // Seto os valores
    setNome( localStorage.getItem('nome') );
    setEmpreendimentoNome( localStorage.getItem('empreendimento_nome') );
    setEmpreendimentoBloco( localStorage.getItem('empreendimento_bloco') );
    
    // Faz a resquest
    api.get('/wp/v2/relatorio_de_obras?filter[meta_key]=nome&filter[meta_value]=' + localStorage.getItem('empreendimento_nome_id') + '&per_page=2').then((response) => {
      setRelatorio(response.data);
      // setColunas(response.data[0].acf.acoes_realizadas.length);
      // setDataFormatada( dayjs(response.data[0].date).format("MM/DD/YYYY") );
      
      setTimeout(100);
      
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>

        <HeaderAreaDoCliente />
        
        <Lista>
          <Loader
            className="Loader"
            type="Rings"
            color="#004291"
            height={100}
            width={100}
          />
        </Lista>
      </>
    );
  } else {
    
    // Se for primeiro acesso
    if ( !jaAcessouUmaVez ) {
      return (
        <>
          <Helmet>
            <title>PRM - Empreendimentos</title>
          </Helmet>
          
          <HeaderAreaDoCliente />

          <Lista>
            <div className="banner">
              <div className="bg" />
            </div>
            <div className="content">
              <div className="Text">
                <h1 className="headerList">Bem-vindo(a) Lucas!</h1>
                <div className="Line" />
                <p className="footerList">
                  A área do Cliente PRM Empreendimentos.
                </p>
              </div>
            
              <Formulario>
                <div>
                  <h2>Primeiramente, confirme seus dados abaixo!</h2>

                  <form 
                  method="POST"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data">
                    <div id="input-box">
                      <label>E-mail:</label>
                      <input
                        type="email"
                        placeholder=""
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div id="input-box">
                      <label>Telefone:</label>
                      {/* <input
                        type="text"
                        placeholder=""
                        value={telefone}
                        onChange={e => setTelefone(e.target.value)}
                      /> */}
                      {/* <Input
                        type="tel"
                        name="telefone"
                        placeholder="Telefone"
                        mask={
                          formik.values.telefone.length < 15
                            ? '(99) 9999-9999?'
                            : '(99) 99999-9999'
                        }
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        maskChar={null}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.telefone}
                      /> */}
                      <InputMask 
                        mask="(99) 99999-9999"
                        placeholder=""
                        type="text"
                        value={telefone}
                        onChange={e => setTelefone(e.target.value)}
                      />
                    </div>
                    <button type="submit">Confirmar</button>
                  </form>
                </div>
              </Formulario>
              
            </div>
          </Lista>
  
          <Footer />
        </>
      );

    // Se não for primeiro acesso
    } else {
      return (
        <>
          <Helmet>
            <title>PRM - Empreendimentos</title>
          </Helmet>
          
          <HeaderAreaDoCliente />
          
          <Lista>
            <div className="banner">
              <div className="bg" />
            </div>
            <div className="content">
              <div className="Text">
                <h1 className="headerList">Bem-vindo(a) {nome}!</h1>
                <div className="Line" />
                <p className="footerList">
                  A área do Cliente PRM Empreendimentos.
                </p>
              </div>
  
              <div className="areaRestrito">
                <div className="tituloEmpreendimento">
                  <div>
                    {/* <img src="https://painel.prmempreendimentos.com.br/wp-content/uploads/2020/11/96d6f2e7e1f705ab5e59c84a6dc009b2-4.png" /> */}
                    <h2>{!!empreendimentoNome && empreendimentoNome}</h2>
                    <h3>{!!empreendimentoBloco && empreendimentoBloco}</h3>
                    
                    <div className="linha"></div>
                  </div>
  
                  <div className="progresso">
                    <Progress type="circle" percent={75} strokeColor="#f89000" />
                    {/* <Progress type="circle" percent={100} format={() => '100%'} /> */}
                  </div>
                </div>
  
                <div className="atualizacoes">

                  {!!relatorio && relatorio.map((obra, index) => (

                    <div className="card tipo1" key={index}>
                      <div className="boxInfo">
                        <div className="logo">
                            {/* <h4>{!!obra.date && dayjs(obra.date).format("MM/DD/YYYY")}</h4> */}
                            <h4>{ReactHtmlParser(!!obra.title && obra.title.rendered)}</h4>
                        </div>
                        <div className={`info info-${!!obra.acf && obra.acf.acoes_realizadas.length}`}>
                          {!!obra.acf.acoes_realizadas && obra.acf.acoes_realizadas.map((acao, index) => {
                            return (
                              <p key={index} className="item">
                                {acao.nome_da_acao}
                              </p>
                            )
                          })}
                        </div>{/* /.info */}
                      </div>{/* /.boxInfo */}
                    </div>
                ))}

                </div>{/* /.atualizacoes */}
              </div>
            </div>
          </Lista>
  
          <Footer />
        </>
      );
    }
  }
}

export default Inicio;