import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Select, Carousel } from 'antd';

import Loader from 'react-loader-spinner';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';

import { Banner, Busca, Vantagens, Lista, Depoimentos, Blog } from './styles';

import api from '../../services/api';
import history from '../../services/history';
import ReactHtmlParser from 'react-html-parser';

const { Option } = Select;

export default function Home() {
  const [cidade, setCidade] = useState('Ponta Grossa');
  const [estado, setEstado] = useState('Parana');
  const bairro = 'Todos';
  const [loading, setLoading] = useState(true);

  function handleSubmit() {
    history.push(`/busca?estado=${estado}&cidade=${cidade}&bairro=${bairro}`);
  }
  function handleEstadoChange(value) {
    setEstado(value);
  }
  function handleCidadeChange(value) {
    setCidade(value);
  }

  const [dados, setDados] = useState();
  const [post, setPost] = useState();

  useEffect(() => {
    api.get('/wp/v2/posts?_embed').then((response) => {
      setPost(response.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/pages?slug=home').then((response) => {
      setDados(response.data[0]);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/empreendimentos').then((response) => {
      setEmpreendimento(response.data);
    });
  }, []);

  const [empreendimento, setEmpreendimento] = useState();

  const banner = useRef();

  const bannerSettings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Banner>
          <Carousel ref={banner} {...bannerSettings}>
            <div className="slider">
              <Loader
                type="Rings"
                color="#004291"
                height={100}
                width={100}
                className="Loader"
              />
            </div>
          </Carousel>
        </Banner>
        <Busca>
          <div className="Text">
            <p>Encontre seu imóvel</p>
            <div className="Line"></div>
          </div>
          <div className="Selects">
            <form onSubmit={handleSubmit}>
              <Select
                allowClear
                placeholder="Estado"
                onChange={handleEstadoChange}
              >
                <Option value="Parana">Paraná</Option>
              </Select>
              <Select
                allowClear
                placeholder="Cidade"
                onChange={handleCidadeChange}
              >
                <Option value="Campo Mourão">Campo Mourão</Option>
                <Option value="Cascavel">Cascavel</Option>
                <Option value="Guarapuava">Guarapuava</Option>
                <Option value="Palmeira">Palmeira</Option>
                <Option value="Ponta Grossa">Ponta Grossa</Option>
                <Option value="Umuarama">Umuarama</Option>
              </Select>
              <Select allowClear placeholder="Bairro">
                <Option value="Todos">Todos</Option>
              </Select>
              <button type="submit">
                <a href="/busca">
                  <p>Buscar</p>
                  <div className="bg" />
                </a>
              </button>
            </form>
          </div>
        </Busca>
        <Vantagens>
          <div className="text">
            <p className="Top">
              As maiores<br></br> vantagens estão
            </p>
            <div className="boxOrange">
              <p className="Bottom">aqui!</p>
            </div>
          </div>
          <div className="boxBanner">
            <img
              src={require('../../assets/img/bannerVantagens.png')}
              alt="bannerVantagens"
            />
          </div>
          <div className="boxBlue" />
          <div className="boxVantagens">
            <div className="firstCollum">
              <img
                src={require('../../assets/icon/IconFinan.svg')}
                alt="IconFinan"
              />
              <p>Financiamento pelo Programa Minha Casa Minha Vida</p>
            </div>
            <div className="secondCollum">
              <img
                src={require('../../assets/icon/IconAgenda.svg')}
                alt="IconAgenda"
              />
              <p>Parcelamento a longo prazo com a incorporadora</p>
            </div>
            <div className="thirdCollum">
              <img
                src={require('../../assets/icon/IconCheck.svg')}
                alt="IconCheck"
              />
              <p>Terrenos prontos para construir</p>
            </div>
            <div className="fourthCollum">
              <img
                src={require('../../assets/icon/IconHandHome.svg')}
                alt="IconHandHome"
              />
              <p>Infraestrutura completa</p>
            </div>
          </div>
        </Vantagens>
        <Lista>
          <div className="Text">
            <p className="headerList">
              Existe um imóvel perfeito<br></br> esperando por você!
            </p>
            <div className="Line" />
            <p className="footerList">
              Desde o projeto inicial até ao acabamento, a PRM Empreendimentos
              cuida de cada detalhe para levar a você e sua família a qualidade
              que merecem!
            </p>
          </div>
          <Loader
            type="Rings"
            color="#004291"
            height={80}
            width={80}
            className="Loader"
          />
        </Lista>
        <Youtube />
        <Blog>
          <div className="Text">
            <p className="headerList">Nosso Blog</p>
            <div className="Line" />
            <p className="footerList">
              Notícias e curiosidades sobre área de Empreendimentos
            </p>
          </div>
          <Loader
            type="Rings"
            color="#004291"
            height={80}
            width={80}
            className="Loader"
          />
        </Blog>
        <Depoimentos id="depoimentos">
          <div className="Text">
            <p className="headerList">O que os nossos clientes tem a dizer</p>
            <div className="Line" />
          </div>
          <Loader type="Rings" color="#004291" height={80} width={80} className="Loader" />
        </Depoimentos>
        <Aluguel />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Banner>
          <Carousel ref={banner} {...bannerSettings}>
            {!!dados &&
              dados.acf.banners.map((banner) => (
                <div key={ Math.random().toString(36).substr(2, 9) } className="slider">
                  <p className="text">
                    A facilidade está <span>aqui!</span>
                  </p>
                  <div className="bg">
                    <img src={banner.imagem.sizes.large} alt="" />
                  </div>
                </div>
              ))}
          </Carousel>
        </Banner>
        <Busca>
          <div className="Text">
            <p>Encontre seu imóvel</p>
            <div className="Line"></div>
          </div>
          <div className="Selects">
            <form onSubmit={handleSubmit}>
              <Select
                allowClear
                placeholder="Estado"
                onChange={handleEstadoChange}
              >
                <Option value="Parana">Paraná</Option>
              </Select>
              <Select
                allowClear
                placeholder="Cidade"
                onChange={handleCidadeChange}
              >
                <Option value="Campo Mourão">Campo Mourão</Option>
                <Option value="Cascavel">Cascavel</Option>
                <Option value="Guarapuava">Guarapuava</Option>
                <Option value="Palmeira">Palmeira</Option>
                <Option value="Ponta Grossa">Ponta Grossa</Option>
                <Option value="Umuarama">Umuarama</Option>
              </Select>
              <Select allowClear placeholder="Bairro">
                <Option value="Todos">Todos</Option>
              </Select>
              <button type="submit">
                <a href="/busca">
                  <p>Buscar</p>
                  <div className="bg" />
                </a>
              </button>
            </form>
          </div>
        </Busca>
        <Vantagens>
          <div className="text">
            <p className="Top">
              As maiores<br></br> vantagens estão
            </p>
            <div className="boxOrange">
              <p className="Bottom">aqui!</p>
            </div>
          </div>
          <div className="boxBanner">
            <img
              src={require('../../assets/img/bannerVantagens.png')}
              alt="bannerVantagens"
            />
          </div>
          <div className="boxBlue" />
          <div className="boxVantagens">
            <div className="firstCollum">
              <img
                src={require('../../assets/icon/IconFinan.svg')}
                alt="IconFinan"
              />
              <p>Financiamento pelo Programa Minha Casa Minha Vida</p>
            </div>
            <div className="secondCollum">
              <img
                src={require('../../assets/icon/IconAgenda.svg')}
                alt="IconAgenda"
              />
              <p>Parcelamento a longo prazo com a incorporadora</p>
            </div>
            <div className="thirdCollum">
              <img
                src={require('../../assets/icon/IconCheck.svg')}
                alt="IconCheck"
              />
              <p>Terrenos prontos para construir</p>
            </div>
            <div className="fourthCollum">
              <img
                src={require('../../assets/icon/IconHandHome.svg')}
                alt="IconHandHome"
              />
              <p>Infraestrutura completa</p>
            </div>
          </div>
        </Vantagens>
        <Lista>
          <div className="Text">
            <p className="headerList">
              Existe um imóvel perfeito<br></br> esperando por você!
            </p>
            <div className="Line" />
            <p className="footerList">
              Desde o projeto inicial até ao acabamento, a PRM Empreendimentos
              cuida de cada detalhe para levar a você e sua família a qualidade
              que merecem!
            </p>
          </div>
          <div className="tabelaCards">
            {!!empreendimento &&
              empreendimento.map((residencial, idx) => {
                if (
                  residencial.acf.tipo[0] === 'Home (destaque)' ||
                  residencial.acf.tipo[1] === 'Home (destaque)' ||
                  residencial.acf.tipo[2] === 'Home (destaque)' ||
                  residencial.acf.tipo[3] === 'Home (destaque)'
                ) {
                  return (
                    <div className="card" key={idx}>
                      <a href={`/empreendimentos/${residencial.slug}`}>
                        <div className="boxImg">
                          <img
                            src={residencial.acf.imagem_de_capa.sizes.thumbnail}
                            alt="banner"
                          ></img>
                        </div>
                      </a>
                      <div className="boxInfo">
                        <div className="logo">
                          <a href={`/empreendimentos/${residencial.slug}`}>
                            <img
                              src={!!residencial ? residencial.acf.marca : ''}
                              alt={
                                !!residencial ? residencial.title.rendered : ''
                              }
                            ></img>
                          </a>
                        </div>
                        <div className="info">
                          <p className="first">
                            {residencial.acf.descricoes[0].texto}
                          </p>
                          <p className="second">
                            {residencial.acf.descricoes[1].texto}
                          </p>
                          <p className="third">
                            {residencial.acf.descricoes[2].texto}
                          </p>
                          <p className="fourth">
                            {residencial.acf.descricoes[3].texto}
                          </p>
                        </div>
                        <div className="buttonMore">
                          <a href={`/empreendimentos/${residencial.slug}`}>
                            Conheça este empreendimento
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return <span key={Math.random().toString(36).substr(2, 9)} className="esconder" />;
                }
              })}
          </div>
        </Lista>
        <Youtube />
        <Blog id="blog">
          <div className="Text">
            <p className="headerList">Nosso Blog</p>
            <div className="Line" />
            <p className="footerList">
              Notícias e curiosidades sobre área de Empreendimentos
            </p>
          </div>
          <div className="tabelaCards">
            {!!post &&
              post.map((content, index) => (
                <div key={Math.random().toString(36).substr(2, 9)} className="boxBlog">
                  <a href={`/post/${content.slug}`} className="headerBox">
                    <div className="avatar">
                      <img
                        src={
                          content.featured_media
                            ? content._embedded['wp:featuredmedia']['0']
                                .source_url
                            : ' '
                        }
                        alt={index}
                      />
                    </div>
                    <p className="titleBlog">
                      {!!content && content.title.rendered}
                    </p>
                    <div className="content">
                      {ReactHtmlParser(!!content && content.excerpt.rendered)}
                    </div>
                  </a>
                  <div className="buttonMore">
                    <a href={`/post/${content.slug}`}>Continuar Lendo</a>
                  </div>
                </div>
              ))}
          </div>
          <a href="/blog" className="Blog">
            Mais Notícias
          </a>
        </Blog>
        <Depoimentos id="depoimentos">
          <div className="Text">
            <p className="headerList">O que os nossos clientes tem a dizer</p>
            <div className="Line" />
          </div>
          <div className="tableDepoimentos">
            {!!dados &&
              dados.acf.depoimentos.map((depoimento) => (
                <div key={Math.random().toString(36).substr(2, 9)} className="boxDepoimentos">
                  <div className="boxImg">
                    <img src={depoimento.imagem.sizes.medium} alt=""></img>
                  </div>
                  <p className="title">{depoimento.nome}</p>
                  <p className="subTitle">cliente</p>
                  <div className="texto">
                    <p>{depoimento.texto_depoimento}</p>
                  </div>
                </div>
              ))}
          </div>
        </Depoimentos>
        <Aluguel />
        <Footer />
      </>
    );
  }
}
