import styled from 'styled-components';

export const Content = styled.div`
    @media screen and (max-width: 768px) {
    margin-top: 90px;
    }
  .banner {
    width: 50%;
    height: 70vh;
    right: 0;
    margin-top: 8%;
    z-index: -1;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .Text {
    padding-top: 9%;
    padding-left: 15%;
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      margin: 0;
    }
    .Line {
      margin: 10px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
  }
  .boxForm {
    @media only screen and (max-width: 768px) {
      width: 60%;
    }
    @media only screen and (max-width: 480px) {
      width: 90%;
      margin: auto;
      border-radius: 10px;
    }

    margin-left: 10%;
    width: 50%;
    height: fit-content;
    background: #fff;
    padding: 2% 2% 7% 2%;
    
    form {
      @media only screen and (max-width: 480px) {
        grid-template-columns: 100%;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      span {
        font-size: 14px;
        color: red;
      }
      input {
        @media only screen and (max-width: 480px) {
          border-width: 1px;
        }
        margin: 0;
        border-color: #707070 !important;
        border-radius: 65px;
        padding: 2% 4%;
        border-style: solid;
        border-width: 0.5px;
        color: #575757;
        font-size: 16px;
        width: 100%;
      }
      textarea {
        @media only screen and (max-width: 480px) {
          border-width: 1px;
        }
        resize: none;
        margin: 1%;
        font-size: 18px;
        height: 200px;
        border-color: #707070;
        border-radius: 25px;
        padding: 1% 4%;
        border-style: solid;
        border-width: 0.5px;
        color: #575757;
        font-size: 16px;
      }
      .btn {
        margin-left: 70%;
        button {
          display: flex;
          padding: 7px 0;
          width: 75%;
          cursor: pointer;
          text-align: center;
          background-color: #f89000;
          text-decoration: none;
          border-radius: 40px;
          align-items: center;
          justify-content: center;
          border: none;
          transition: all ease 0.5s;
          font-size: 15px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 10% 12.5% 0 12.5%;
          color: #ffffff;
        }
        button:hover {
          background-color: #ff741d;
        }

        button:active {
          background-color: #ff741d;
          box-shadow: 0 5px #666;
          transform: translateY(4px);
        }
      }
    }
  }
  .lineOrange {
    @media only screen and (max-width: 768px) {
      margin-top: -25px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 10px;
    }
    position: absolute;
    margin-top: -50px;
    margin-left: 25%;
    width: 50%;
    height: 10px;
    background-color: #f89000;
    border-radius: 100px;
  }
  .boxBlue {
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      margin-top: 5%;
      height: auto;
      grid-template-columns: 40% 40%;
      padding: 10% 0;
      grid-gap: 10px;
      margin-bottom: 5%;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 30px;
      grid-template-columns: 1fr;
      height: auto;
      padding: 7%;
    }
    display: grid;
    grid-template-columns: 15.25% 15.25% 15.25% 15.25%;
    background-color: #004291;
    column-gap: 3%;
    margin: auto;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 400px;
    .icons {
      margin: auto;
      .FiPhone {
        @media only screen and (max-width: 480px) {
          height: 50px;
        }
        font-size: 300%;
        path {
          stroke: #f89000;
        }
      }
      .GrClock {
        @media only screen and (max-width: 480px) {
          height: 50px;
        }
        font-size: 300%;
        path {
          stroke: #f89000;
        }
      }
      .GoMail {
        @media only screen and (max-width: 480px) {
          height: 50px;
        }
        font-size: 300%;
        path {
          fill: #f89000;
        }
      }
      .GrLocation {
        @media only screen and (max-width: 480px) {
          height: 50px;
        }
        font-size: 300%;
        path {
          stroke: #f89000;
        }
      }
    }
    .tel {
      @media only screen and (max-width: 768px) {
        grid-row: 2;
        height: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      margin-bottom: 5%;
      a {
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        color: #fff;
        opacity: 1;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .hour {
      @media only screen and (max-width: 480px) {
        grid-row: 6;
        height: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      margin-bottom: 5%;
      p {
        align-items: center;
        justify-content: center;
        display: flex;
        color: #fff;
        opacity: 1;
        font-size: 16px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .email {
      @media only screen and (max-width: 768px) {
        grid-row: 2;
        height: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      @media only screen and (max-width: 480px) {
        grid-row: 4;
        height: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      margin-bottom: 5%;
      a {
        margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        color: #fff;
        opacity: 1;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
      }
    }
    .location {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        height: 70px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      @media only screen and (max-width: 480px) {
        height: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;
      }
      margin-bottom: 5%;
      p {
        margin: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        color: #fff;
        opacity: 1;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        max-width: 175px;
        line-height: 25px;
      }
    }
    .smallLineOrange {
      @media only screen and (max-width: 768px) {
        display: none;
      }
      display: flex;
      margin: auto;
      width: 45px;
      height: 4px;
      background-color: #f89000;
      border-radius: 40px;
    }
  }
`;
