import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Loader from 'react-loader-spinner';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';

import { Lista } from './styles';

import api from '../../services/api';

export default function Lançamentos() {
  const [empreendimento, setEmpreendimento] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/empreendimentos').then((response) => {
      setEmpreendimento(response.data);
      setTimeout(100);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Lista>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Lançamentos</p>
              <div className="Line" />
              <p className="footerList">
                O seu próximo lar está aqui. Conheça os nossos lançamentos!
              </p>
            </div>
            <div className="tabelaCards">
              <span />
              <Loader
                className="Loader"
                type="Rings"
                color="#004291"
                height={100}
                width={100}
              />
              <span />
            </div>
          </div>
        </Lista>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Lista>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Lançamentos</p>
              <div className="Line" />
              <p className="footerList">
                O seu próximo lar está aqui. Conheça os nossos lançamentos!
              </p>
            </div>
            <div className="tabelaCards">
              {!!empreendimento &&
                empreendimento.map((residencial, idx) => {
                  if (
                    residencial.acf.tipo[0] === 'Lançamento' ||
                    residencial.acf.tipo[1] === 'Lançamento' ||
                    residencial.acf.tipo[2] === 'Lançamento' ||
                    residencial.acf.tipo[3] === 'Lançamento'
                  ) {
                    return (
                      <div className="card" key={idx}>
                        <a href={`/empreendimentos/${residencial.slug}`}>
                          <div className="boxImg">
                            <img
                              src={
                                residencial.acf.imagem_de_capa.sizes.thumbnail
                              }
                              alt="banner"
                            ></img>
                          </div>
                        </a>
                        <div className="boxInfo">
                          <div className="logo">
                            <a href={`/empreendimentos/${residencial.slug}`}>
                              <img
                                src={!!residencial ? residencial.acf.marca : ''}
                                alt={
                                  !!residencial
                                    ? residencial.title.rendered
                                    : ''
                                }
                              ></img>
                            </a>
                          </div>
                          <div className="info">
                            <p className="first">
                              {residencial.acf.descricoes[0].texto}
                            </p>
                            <p className="second">
                              {residencial.acf.descricoes[1].texto}
                            </p>
                            <p className="third">
                              {residencial.acf.descricoes[2].texto}
                            </p>
                            <p className="fourth">
                              {residencial.acf.descricoes[3].texto}
                            </p>
                          </div>
                          <div className="buttonMore">
                            <a href={`/empreendimentos/${residencial.slug}`}>
                              Conheça este empreendimento
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return <span className="esconder" />;
                  }
                })}
            </div>
          </div>
        </Lista>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  }
}
