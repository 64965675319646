import React, { useState } from 'react';
// imports de dependencias
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';
import ademail from '../../services/ademail';
// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';

import { Content } from './styles';


// imports para estilização
import { FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';
import { GrClock } from 'react-icons/gr';
import { GrLocation } from 'react-icons/gr';

export default function Contato() {

  const [disabledButton, setdisabledButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo é obrigatório'),
      telefone: Yup.string().required('*Campo é obrigatório'),
      mensagem: Yup.string().required('*Campo é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      setdisabledButton(true);

      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Mensagem: ${values.mensagem}</p>
      `;

      const mail = {
        to: 'contato@prmempreendimentos.com.br',
        from: values.email,
        as: values.nome,
        subject: 'Novo contato via site - PRM',
        message: body,
      };

      try {
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });
        // Sucesso ao enviar
        setdisabledButton(false);
        resetForm();
      } catch (error) {
        // Erro ao enviar
        setdisabledButton(false);
        notification.error({
          message: 'Não foi possivel concluir o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });
  return (
    <>
      <Helmet>
        <title>PRM - Empreendimentos</title>
      </Helmet>
      <Header />
      <Content>
        <div className="banner">
          <img
            src={require('../../assets/img/bannerContato.jpg')}
            alt="bannerSobre"
          />
        </div>
        <div className="Text">
          <p className="headerList">Contato</p>
          <div className="Line" />
        </div>
        <div className="boxForm">
          <div>
            <h2 className="boxForm_h2">
              Fale conosco
            </h2>
            <p className="boxForm_sac">
              SAC: (41) 99146-6488
            </p>
            <p className="boxForm_financeiro">
              Financeiro: (41) 99125-5026
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              name="nome"
              placeholder="Nome"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nome}
            />
            {formik.touched.nome && formik.errors.nome ? (
              <span className="erro">{formik.errors.nome}</span>
            ) : null}
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="erro">{formik.errors.email}</span>
            ) : null}
            <input
              type="tel"
              name="telefone"
              mask={
                formik.values.telefone.length <= 14
                  ? '(99) 9999-9999?'
                  : '(99) 99999-9999'
              }
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar={null}
              placeholder="Telefone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telefone}
            />
            {formik.touched.telefone && formik.errors.telefone ? (
              <span>{formik.errors.telefone}</span>
            ) : null}
            <textarea
              placeholder="Mensagem"
              name="mensagem"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mensagem}
            />
            {formik.touched.mensagem && formik.errors.mensagem ? (
              <span className="erro">{formik.errors.mensagem}</span>
            ) : null}
            <div className="btn">
              <button type="submit" className={disabledButton ? 'enable' : ''}  disabled={disabledButton ? 'disabled' : ''} >ENVIAR</button>
            </div>
          </form>
        </div>
        <div className="lineOrange" />
        <div className="boxBlue">
          <div className="icons">
            <FiPhone className="FiPhone" />
          </div>
          <div className="icons">
            <GoMail className="GoMail" />
          </div>
          <div className="icons">
            <GrClock className="GrClock" />
          </div>
          <div className="icons">
            <GrLocation className="GrLocation" />
          </div>
          <div className="tel">
            <a href="tel: +554135324224">(41) 3532.4224</a>
            <a href="tel: +554133282442">(41) 3328.2442</a>
          </div>
          <div className="email">
            <a href="mailto: contato@prmempreendimentos.com.br">
              contato@prmempreendimentos.com.br
            </a>
          </div>
          <div className="hour">
            <p>Seg. a Sex. das 8h às 18h</p>
          </div>
          <div className="location">
            <p>Rua Dep. Nilson Ribas Nº 845 | Seminário Curitiba | PR</p>
          </div>
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
        </div>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.767191122587!2d-49.31254798498541!3d-25.446045783780995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3b8d8e9aa57%3A0xc2eb0540c748a762!2sR.%20Dep.%20Nilson%20Ribas%2C%20845%20-%20Semin%C3%A1rio%2C%20Curitiba%20-%20PR%2C%2080740-510!5e0!3m2!1spt-BR!2sbr!4v1600886090688!5m2!1spt-BR!2sbr"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </Content>
      {/* <Youtube /> */}
      <Aluguel />
      <Footer />
    </>
  );
}
