import styled, { keyframes } from 'styled-components';
import bannerFooter from '../../assets/img/bannerFooter.png';
import iconWhats1 from '../../assets/icon/IconWhats.svg';
import iconWhats2 from '../../assets/icon/IconWhats2.svg';

const heartBeat = keyframes`
        0%, 100%, 40%, 60% {
            transform: scale(1);
        }
        20%, 80% {
            transform: scale(1.3);
        }
    `;

export const News = styled.div`
  @media only screen and (max-width: 768px) {
    border-radius: 0;
    width: 100%;
    padding: 25px 5%;
    height: auto;
  }

  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  width: 70%;
  top: 60px;
  height: 120px;
  background-color: #004291;
  border-radius: 100px;
  .News {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
    .firstCollum {
      @media only screen and (max-width: 768px) {
        border-radius: 0;
        width: 100%;
      }
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        @media only screen and (max-width: 768px) {
        margin-left: 0;
        width: 10%;
        }
        margin-left: 10%;
        margin-right: 5%;
        object-fit: cover;
        width: 15%;
        height: 7%;
      }
      .newsText {
        @media only screen and (max-width: 768px) {
          font-size: 15px;
          max-width: none;
        }
        max-width: 70%;
        width: 100%;
        text-align: left;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        font-size: 22px;
        letter-spacing: 0px;
        line-height: 100%;
      }
    }
    form {
      padding-top: 10px;
      @media only screen and (max-width: 768px) {
        border-radius: 0;
        width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      .inputEmail {
        width: 65%;
        height: 35px;
        input {
          padding: 5px 15px;
          height: 100%;
          width: 100%;
          text-decoration: none;
          border-radius: 40px;
          border: none;
        }
      }
      span {
        font-size: 14px;
        color: red;
      }
      button {
    @media only screen and (max-width: 768px) {
    width: 25%;
    }
        display: flex;
        padding: 7px 0;
        width: 25%;
        cursor: pointer;
        text-align: center;
        background-color: #f89000;
        text-decoration: none;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
        border: none;
        transition: all ease 0.5s;
        p {
          font-size: 15px;
          letter-spacing: 0px;
          font-weight: bold;
          color: #ffffff;
          margin: 0;
        }
      }
      button:hover {
        background-color: #ff741d;
      }

      button:active {
        background-color: #ff741d;
        box-shadow: 0 5px #666;
        transform: translateY(4px);
      }
    }
  }
`;

export const Content = styled.div`
    @media only screen and (max-width: 480px) {
    padding: 5%;
    }
  position: relative;
  width: 100%;
  height: fit-content;
  background-image: url(${bannerFooter});
  background-color: #f89000;
  background-size: cover;
  background-position: center;
  .middleFooter {
    @media only screen and (max-width: 768px) {
    padding: 10% 5%;
    }
    @media only screen and (max-width: 685px) {
      grid-template-columns: 1fr;
      width: 100%;
      padding: 5%;
    }
    display: grid;
    grid-template-columns: 30% 20% 30%;
    justify-content: space-evenly;
    color: #fff;
    text-decoration: none;
    padding: 10% 15%;
    .FCollum {
      flex-direction: column;
      text-decoration: none;
      font-size: 15px;
      letter-spacing: 0px;
      font-weight: bold;
      color: #fff;
      margin: 0;
      top: 50px;
      justify-content: center;
      display: flex;

      ul {
        @media only screen and (max-width: 685px) {
          padding: 5% 0;
          margin-top: 5%;
        }
        flex-direction: column;
        display: flex;
        li {
          display: flex;
          list-style: none;
          align-items: left;
          a {
            @media only screen and (max-width: 685px) {
              font-size: 24px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 320px) {
              font-size: 16px;
            }
            display: flex;
            color: #fff;
            opacity: 1;
            text-decoration: none;
            font-size: 20px;
            letter-spacing: 0px;
            position: relative;
            padding: 10px 10px;
          }
          a:after {
            content: '';
            margin-top: 30px;
            display: flex;
            height: 3px;
            border-radius: 15px;
            left: 50%;
            position: absolute;
            background: #fff;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }
          a:hover:after {
            width: 100%;
            left: 0;
          }
        }
      }
      .Certificacao {
        @media only screen and (min-width: 480px) and (max-width: 685px)  {
        width: 70%;
        }
        background-color: #fff;
        position: relative;
        display: flex;
        width: 100%;
        display: grid;
        grid-template-columns: 22% 22% 22% 22%;
        justify-content: space-evenly;
        padding: 20px 10px;
        .iso {
          width: 100%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .pbpq {
          width: 100%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .minhaCasa {
          width: 100%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .Caixa {
          width: 100%;
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .SCollum {
      @media only screen and (min-width: 480px) and (max-width: 685px){
      padding: 5%;
      }
      @media only screen and (max-width: 480px) {
        padding: 15%;
      }
      display: flex;
      flex-direction: column;
      flex-direction: column;
      align-items: center;
      margin: auto;
      .LogoF {
      @media only screen and (min-width: 480px) and (max-width: 685px){
      width: 100%;
      }
        justify-content: center;
        align-items: center;
        width: 80%;
        a {
          img {
            object-fit: cover;
            width: 100%;
            transition: transform 0.7s;
          }
          img:hover {
            transform: scale(1.1);
          }
        }
      }
      .RedeSocial {
        justify-content: space-between;
        margin-top: 5%;
        .Facebook {
          font-size: 300%;
          color: #004291;
          transition: transform 0.7s;
        }
        .Facebook:hover {
          transform: scale(1.1);
        }
        .Instagram {
          font-size: 300%;
          transition: transform 0.7s;
          color: #004291;
        }
        .Instagram:hover {
          transform: scale(1.1);
        }
      }
    }
    .TCollum {
      @media only screen and (max-width: 685px) {
        padding: 5%;
      }
      display: flex;
      list-style: none;
      flex-direction: column;
      text-decoration: none;
      align-items: left;
      .Whats {
        @media only screen and (min-width: 480px) and (max-width: 685px){
          width: 50%;
          margin: auto;
        }

        @media only screen and (max-width: 480px){
        margin: auto;
        }
        padding: 20px 20px;
        margin-bottom: 5%;
        display: flex;
        border-radius: 40px;
        width: 75%;
        height: 80px;
        z-index: 1;
        transition: all linear 0.3s;
        background-color: #4caf50;
        background-image: url(${iconWhats1});
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;

        cursor: pointer;
        a {
          width: 100%;
          height: 100%;
        }
      }
      .Whats:hover {
        background-color: #004291;
        background-image: url(${iconWhats2});
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;
      }
      .Contato {
        display: flex;
        list-style: none;
        flex-direction: column;
        .Tel {
          display: flex;
          list-style: none;
          flex-direction: column;
          .FiPhone {
            font-size: 275%;
          }
          a {
            @media only screen and (max-width: 685px) {
              font-size: 16px;
            }
            display: flex;
            color: #fff;
            opacity: 1;
            text-decoration: none;
            font-size: 20px;
            letter-spacing: 0px;
            position: relative;
            font-weight: bold;
          }
        }
        .line {
          width: 45px;
          height: 4px;
          background-color: #004291;
          border-radius: 40px;
          margin: 5% 0;
        }
        .Mail {
          display: flex;
          list-style: none;
          flex-direction: column;
          .GoMail {
            font-size: 275%;
          }
          a {
            @media only screen and (max-width: 768px) {
              font-size: 14px;
            }

            display: flex;
            color: #fff;
            opacity: 1;
            text-decoration: none;
            font-size: 20px;
            letter-spacing: 0px;
            position: relative;
            font-weight: bold;
          }
        }
        .Glock {
          .GrGlock {
            font-size: 275%;
            path {
              stroke: #fff;
            }
          }
          p {
            @media only screen and (max-width: 685px) {
              font-size: 16px;
            }
            display: flex;
            color: #fff;
            opacity: 1;
            text-decoration: none;
            font-size: 20px;
            letter-spacing: 0px;
            position: relative;
            font-weight: bold;
          }
        }
      }
    }
  }
`;

export const Info = styled.footer`
  padding: 20px 20px 30px 20px;
  background-color: #fff;
  width: 100%;

  @media (max-width: 700px) {
    padding: 20px 20px 25px 20px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: auto;

    @media (max-width: 1440px) {
      width: 80%;
    }

    @media (max-width: 1150px) {
      width: 100%;
    }

    @media (max-width: 980px) {
      flex-direction: column;
    }

    @media (max-width: 950px) {
      flex-direction: row;
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  p {
    @media (max-width: 980px) {
      margin-bottom: 5px !important;
    }

    @media (max-width: 950px) {
      margin-bottom: 0px !important;
    }

    @media (max-width: 700px) {
      margin-bottom: 5px !important;
    }
  }

  p,
  a {
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
    font-family: 'Libre Franklin', sans-serif;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 12px;
    }
  }

  img {
    margin: 0 8px;
    height: 15px;
    position: relative;
    animation-name: ${heartBeat};
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;
