import styled from 'styled-components';
import banner from '../../../assets/img/bgLancamento.png';

// Wrapper
export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10% 0 0 0;

  .background {
    position: absolute;
    height: 600px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    z-index: -1;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .banner {
    position: absolute;
    width: 100%;
    height: 50vh;
    background: #f89000;
    top: 0;
    left: 0;
  
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(248, 144, 0, 0.68);
      background: url(${banner});
      opacity: 0.1;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
    }
  }

  .content {
    position: relative;

    .Text {
      padding-left: 15%;
    }

    .Line {
      margin: 20px 0 60px 0;
      width: 80px;
      height: 10px;
      background-color: #004291;
      border-radius: 100px;
    }
    
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #fff;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      margin: 0;
    }
  }

  .container {
    position: relative;
    width: 30vw;
    margin: auto;
    padding: 60px;
    background: #fff;
    top: -25px;

    button {
      height: 40px;
      border-radius: 50px;
      border: none;
      color: #fff;
      font-weight: 900;
      font-size: 16px;
      -webkit-transition: all cubic-bezier(0.4,0,1,1) .3s;
      transition: all cubic-bezier(0.4,0,1,1) .3s;
      text-transform: uppercase;
      margin-top: 15px;
      width: 100%;
      cursor: pointer;
    }
  }
`;

// Formulario
export const Formulario = styled.div`

  div {
    position: relative;

    form {
      label {
        display: block;
        color: #fff;
        padding-bottom: 3px;
        padding-left: 20px;
        color: #000;
        font-size: 20px;
        font-weight: bold;
      }

      input {
        width: 100%;
        border-radius: 20px;
        height: 40px;
        margin-bottom: 10px;
        text-indent: 20px;
        -webkit-transition: all cubic-bezier(0.4,0,1,1) .3s;
        transition: all cubic-bezier(0.4,0,1,1) .3s;
        border: 2px solid #f89000;

        :focus {
          border-color: #007CCD;
        }
      }
    }

    button {
      background: #007CCD;

      :hover {
        background: #005A9C;
      }
    }

    .esqueceu {
      text-align: center;
      display: block;
      width: 85%;
      font-size: 18px;
      color: #fff;
      font-weight: 900;
      margin-top: 15px;

      span {
        color: #233559;
      }
    }
  }
`;