import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';
import api from '../../../services/api';

// components
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Youtube from '../../../components/Youtube';
import Aluguel from '../../../components/Aluguel';
import ReactHtmlParser from 'react-html-parser';

import { Content, Top, Cards } from './styles';

export default function Post({ match }) {
  const [dados, setDados] = useState();
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);

  const slug = match.params.slug;

  useEffect(() => {
    setLoading(true);
    api.get(`/wp/v2/posts?slug=${slug}&_embed`).then((response) => {
      setDados(response.data[0]);
      setLoading(false);
    });
  }, [slug]);

  useEffect(() => {
    api.get('/wp/v2/posts?_embed').then((response) => {
      setPost(response.data);
    });
  }, []);
  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Top>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Blog</p>
              <div className="Line" />
              <p className="footerList">
                Notícias e curiosidades sobre área de Empreendimentos
              </p>
            </div>
          </div>
        </Top>
        <Content> 
          <Loader
            className="Loader"
            type="Rings"
            color="#004291"
            height={100}
            width={100}
          />
        </Content>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Top>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Blog</p>
              <div className="Line" />
              <p className="footerList">
                Notícias e curiosidades sobre área de Empreendimentos
              </p>
            </div>
          </div>
        </Top>

        <Content>
          <div className="singlePost">
            <h1>{!!dados && dados.title.rendered}</h1>
            <div className="Conteudo">
              {ReactHtmlParser(!!dados && dados.content.rendered)}
            </div>
          </div>
          <div className="Repost"></div>
        </Content>
        <Cards>
          <div className="content">
            <div className="Text">
              <p className="headerList">Outras notícias</p>
              <div className="Line" />
              <p className="footerList">Notícias que possam te interessar</p>
            </div>
          </div>
          <div className="tabelaCards">
            {!!post &&
              post.map((content, index) => (
                <div className="boxBlog">
                  <a href={`/post/${content.slug}`} className="headerBox">
                    <div className="avatar">
                      <img
                        src={
                          content.featured_media
                            ? content._embedded['wp:featuredmedia']['0']
                                .source_url
                            : ' '
                        }
                        alt={index}
                      />
                    </div>
                    <p className="titleBlog">
                      {!!content && content.title.rendered}
                    </p>
                    <p className="content">
                      {ReactHtmlParser(!!content && content.excerpt.rendered)}
                    </p>
                  </a>
                  <div className="buttonMore">
                    <a href={`/post/${content.slug}`}>Continuar Lendo</a>
                  </div>
                </div>
              ))}
          </div>
        </Cards>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  }
}
