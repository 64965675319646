import styled from 'styled-components';

// Loading
export const Loading = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
  }

  p {
    font-size: 22px;
    color: #007ccc;
    margin-top: 25px;
  }
`;