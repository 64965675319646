import React from 'react';

import { Video } from './styles';
import Videomp4 from '../../assets/prm_2024.mp4';

export default function Youtube() {
  return (
    <>
      <Video>
        <div className="Header">
          <p className="Title">
            A PRM faz a diferença na vida de nossos clientes
          </p>
          <div className="Line" />
          <p className="subTitle">
            Conheça um pouco mais da história da PRM Empreendimentos
          </p>
        </div>
        <div className="bgBlue" />
        <div className="bgOrange" />
        <div className="iFrame">
          <video src={Videomp4} controls />
        </div>
      </Video>
    </>
  );
}
