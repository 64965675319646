import React from 'react';

import { Content } from './styles';

export default function Aluguel() {
  return (
    <>
      <Content>
        <div className="iconHappy">
          <img
            src={require('../../assets/icon/happyHeart.svg')}
            alt="happyHeart"
          />
        </div>
        <div className="Text">
          <div className="Header">
            <p>
              Faça como os nossos<br></br> clientes e saia logo do aluguel!
            </p>
          </div>
          <div className="Footer">
            <p>
              Oportunidades como as que a PRM Empreendimentos<br></br> oferece,
              não é sempre que vemos por aí.
            </p>
          </div>
        </div>
      </Content>
    </>
  );
}
