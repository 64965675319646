import styled from 'styled-components';

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 15%;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding: 5%;
  }

  background-color: #f5f5f5;
  position: relative;
  width: 100%;
  height: fit-content;
  align-content: center;
  display: flex;
  justify-content: center;
  padding: 7% 0;
  .iconHappy {
    @media only screen and (max-width: 768px) {
      width: 35%;
    }
    @media only screen and (max-width: 480px) {
      width: 40%;
      margin: auto;
      padding: 5%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin-right: 5%;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .Text {
    @media only screen and (max-width: 480px) {
      padding: unset;
      margin: auto;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    .Header {
      p {
        @media only screen and (max-width: 768px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 36px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 50px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        line-height: 90%;
        margin: 0 0;
      }
    }
    .Footer {
      p {
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          margin-top: 3%;
          line-height: 20px;
        }

        @media only screen and (max-width: 480px) {
          margin-top: 20px;
          font-size: 14px;
          line-height: 22px;
        }

        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        font-size: 20px;
        margin: 0 0;
      }
    }
  }
`;
