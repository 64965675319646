import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Loader from 'react-loader-spinner';

// services
import history from '../../services/history';
import api from '../../services/api';

// styles
import { Loading } from './styles';

export default function PrivateRoute({ component: Component, ...rest }) {

  // State
  const [isOk, setIsOk] = useState(false);
  
  // Objeto de dados do form
  const dadosLogin = new FormData();
  dadosLogin.append('token', localStorage.getItem('token'));

  // Pega o token
  const newToken = dadosLogin.get('token');

  const options = {
    headers: {
      Authorization: `Bearer ${newToken}`,
      'content-type': 'multipart/form-data',
    },
  };

  useEffect(() => {
    async function checkLogin() {
      try {
        await api.post('/jwt-auth/v1/token/validate', dadosLogin, options);
        setIsOk(true);
      
      } catch (err) {  
        localStorage.clear();
        history.push('/area-do-cliente/login');
        return false;
      }
      return true;
    }
    checkLogin();
    // eslint-disable-next-line
  }, []);

  return !!isOk ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <>
      <Loading>
        <div>
          <Loader
            type="Rings"
            color="#007ccc"
            height={300}
            width={300}
          />
          <p>Carregando ...</p>
        </div>
      </Loading>
    </>
  );
}
