import styled from 'styled-components';
import banner from '../../../assets/img/bgLancamento.png';

export const Lista = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 90px;
  }
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10% 0 0 0;
  .banner {
    position: absolute;
    width: 100%;
    height: 50vh;
    background: #f89000;
    top: 0;
    left: 0;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(248, 144, 0, 0.68);
      background: url(${banner});
      opacity: 0.1;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
    }
  }
  .content {
    position: relative;
    .Text {
      @media only screen and (max-width: 768px) {
        padding: 5%;
      }
      padding-left: 15%;
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
      }
      .Line {
        margin: 20px 0;
        width: 80px;
        height: 10px;
        background-color: #004291;
        border-radius: 100px;
      }
      .footerList {
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 22px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 90%;
        margin: 0;
      }
    }
    .areaRestrito {
      .tituloEmpreendimento {
        display: grid;
        grid-template-columns: 85% 15%;

        .progresso {
          text-align: right;
        }

        img { 
          height: 100px;
          margin-bottom: 15px;
        }

        h2 {
          color: #004291;
          opacity: 1;
          -webkit-text-decoration: none;
          text-decoration: none;
          font-size: 60px;
          -webkit-letter-spacing: 0px;
          -moz-letter-spacing: 0px;
          -ms-letter-spacing: 0px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
        }

        h3 {
          color: #004291;
          opacity: 1;
          -webkit-text-decoration: none;
          text-decoration: none;
          font-size: 40px;
          -webkit-letter-spacing: 0px;
          -moz-letter-spacing: 0px;
          -ms-letter-spacing: 0px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          margin: 0;
        }

        .linha {
          margin: 10px 0;
          width: 80px;
          height: 10px;
          background-color: #f89000;
          border-radius: 100px;
        }
      }

      top: -25px;
      position: relative;
      z-index: 1;
      background-color: #fff;
      margin: 5% 15%;
      padding: 2.5%;
      justify-content: center;

      .atualizacoes{
        margin-top: 30px;
        display: grid;
        grid-template-columns: 48% 48%;
        grid-gap: 40px 4%;
      }

      .card {
        @media only screen and (max-width: 768px) {
          padding: 5%;
          height: auto;
        }
        margin: auto;        
        height: fit-content;
        width: 100%;
        background-color: #ededed;
        padding; 20px;

        a {
          .boxImg {
            padding: 2%;
            display: flex;
            justify-content: center;
            align-content: center;
            margin: auto;
            width: 100%;
            height: 100%;
            transition: transform 0.5s;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          .boxImg:hover {
            transform: scale(1.1);
          }
        }
        .boxInfo {
          .logo {
            padding: 10px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;

            h4 {
              font-weight: bold;
              font-size: 30px;
              margin: 0;
              text-transform: uppercase;
            }

            a {
              display: flex;
              height: 71px;
            }
            img {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
          .info {
            display: grid;
            grid-gap: 0px;
            justify-content: center;
            align-content: center;
            line-height: 120%;

            .item {
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              letter-spacing: 0px;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
              margin: 0;
              padding: 5%;
              text-align: center;

              &:nth-of-type(odd) {
                background-color: #f89000;
              }

              &:nth-of-type(even) {
                background-color: #094291;
              }
            }
          }

          .buttonMore {
            width: 90%;
            height: 35px;
            border-radius: 100px;
            background-color: #004291;
            display: flex;
            margin: 5%;
            transition: all linear 0.3s;

            a {
              color: #fff;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              text-decoration: none;
              display: flex;
              width: 100%;
              height: 100%;
              margin: auto;
            }
          }
          .buttonMore:hover {
            background-color: #f89000;
          }
        }
      }

      .card .info-1 {
        grid-template-columns: 100%;
      }

      .card .info-2 {
        grid-template-columns: 50% 50%;
      }

      .card .info-3 {
        grid-template-columns: 33.3% 33.3% 33.4%;
      }

      .card .info-4 {
        grid-template-columns: 25% 25% 25% 25%;
      }

      .esconder {
        display: none;
      }
      .Loader {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
`;

// Wrapper
export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10% 0 0 0;

  .background {
    position: absolute;
    height: 600px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    z-index: -1;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .banner {
    position: absolute;
    width: 100%;
    height: 50vh;
    background: #f89000;
    top: 0;
    left: 0;
  
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(248, 144, 0, 0.68);
      background: url(${banner});
      opacity: 0.1;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
    }
  }

  .content {
    position: relative;

    .Text {
      padding-left: 15%;
    }

    .Line {
      margin: 20px 0 60px 0;
      width: 80px;
      height: 10px;
      background-color: #004291;
      border-radius: 100px;
    }
    
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #fff;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      margin: 0;
    }
  }

  .container {
    position: relative;
    width: 30vw;
    margin: auto;
    padding: 60px;
    background: #fff;
    top: -25px;
  }
`;

// Formulario
export const Formulario = styled.div`
  margin-bottom: 50px;

  > div {
    position: relative;
    width: 30vw;
    margin: auto;
    padding: 60px;
    background: #fff;
    top: 45px;

    h2 {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      line-height: 110%;
    }

    form {
      label {
        display: block;
        color: #fff;
        padding-bottom: 3px;
        padding-left: 20px;
        color: #000;
        font-size: 20px;
        font-weight: bold;
      }

      input {
        width: 100%;
        border-radius: 20px;
        height: 40px;
        margin-bottom: 10px;
        text-indent: 20px;
        -webkit-transition: all cubic-bezier(0.4,0,1,1) .3s;
        transition: all cubic-bezier(0.4,0,1,1) .3s;
        border: 2px solid #f89000;

        :focus {
          border-color: #007CCD;
        }
      }
    }

    button {
      height: 40px;
      border-radius: 50px;
      border: none;
      color: #fff;
      font-weight: 900;
      font-size: 16px;
      -webkit-transition: all cubic-bezier(0.4,0,1,1) .3s;
      transition: all cubic-bezier(0.4,0,1,1) .3s;
      text-transform: uppercase;
      margin-top: 15px;
      width: 100%;
      cursor: pointer;
      background: green;

      :hover {
        background: #005A9C;
      }
    }

    .esqueceu {
      text-align: center;
      display: block;
      width: 85%;
      font-size: 18px;
      color: #fff;
      font-weight: 900;
      margin-top: 15px;

      span {
        color: #233559;
      }
    }
  }
`;