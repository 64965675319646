import React, { useState } from 'react';
// import { Helmet } from 'react-helmet';
import { notification } from 'antd';

// services
import api from '../../../services/api';
import history from '../../../services/history';

// components
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

// styles
import { 
  Wrapper, Formulario
} from './styles';

function Login() {

  // states
  // const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // handleSubmit
  async function handleSubmit(e) {
    e.preventDefault();

    const loginData = new FormData();
    loginData.append('username', email);
    loginData.append('password', password);

    let axiosConfig = {
      headers: {
        'Content-Type': 'multipart/formdata',
      },
    };

    try {
      await api
        .post('/jwt-auth/v1/token', loginData, axiosConfig)
        .then((res) => {
            
            notification.success({
              message: 'Login realizado com sucesso!',
              placement: 'bottomRight',
            });

            localStorage.setItem('token', res.data.token);
            localStorage.setItem('nome', res.data.nome);
            localStorage.setItem('id', res.data.id);
            localStorage.setItem('email', res.data.user_email);
            localStorage.setItem('telefone', res.data.telefone);
            localStorage.setItem('ja_acessou_uma_vez', res.data.primeiro_acesso);
            
            localStorage.setItem('empreendimento_nome', res.data.empreendimento_nome.post_title);
            localStorage.setItem('empreendimento_nome_id', res.data.empreendimento_nome.ID);

            localStorage.setItem('empreendimento_bloco', res.data.empreendimento_bloco.name);
            localStorage.setItem('empreendimento_bloco_id', res.data.empreendimento_bloco.term_id);
            
            localStorage.setItem('empreendimento_unidade', res.data.empreendimento_unidade);            
            
            history.push('/area-do-cliente');
        });
    } catch (err) {
      localStorage.clear();
      
      notification.error({
        message: 'Não foi possivel realizar login! 😔',
        description: 'Verfique os seus dados e tente novamente!',
        placement: 'bottomRight',
      });

      console.log( err.response.data );
    }
  }

  return (
  <>
    {/* <Helmet>
      <title>
        Login | Área do Usuário Obras Públicas
      </title>
      
      <meta property="og:type" content="website" />
      
      <meta
        name="description"
        content="O site obras públicas é um sistema de assessoria sobre obras públicas licitadas. Acompanhe o resultado as licitações e mantenha-se
        informado sobre futuros resultados."
      />
      <meta
        name="keywords"
        content="obras, obras públicas, assessoria para licitação, licitação, sistema de licitações, licitações públicas"
      />
      <meta
        property="og:image"
        content={process.env.PUBLIC_URL + '/screenshot.png'}
      />
    </Helmet> */}

    <Header />

    <main>
      <Wrapper>
        
        <div className="banner">
          <div className="bg" />
        </div>
        
        <div className="content">
          <div className="Text">
            <p className="headerList">Área do Cliente PRM</p>
            <div className="Line" />
          </div>
        </div>

        <div className="container">
          <Formulario>
            <div>
              <form 
              method="POST"
              onSubmit={handleSubmit}
              encType="multipart/form-data">
                <div id="input-box">
                  <label>E-mail:</label>
                  <input
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div id="input-box">
                  <label>Senha:</label>
                  <input
                    type="password"
                    placeholder=""
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit">Entrar</button>
              </form>
            </div>
          </Formulario>
        </div>
      </Wrapper>
    </main>

    <Footer />
  </>
  );
}

export default Login;