import styled from 'styled-components';

export const About = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 90px;
  }
  .banner {
    width: 60%;
    height: 70vh;
    right: 0;
    margin-top: 5%;
    z-index: -1;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .Text {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    padding-top: 10%;
    padding-left: 15%;
    .headerList {
      @media only screen and (max-width: 480px) {
        font-size: 40px;
      }
      color: #004291;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 60px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      margin: 0;
    }
    .Line {
      margin: 20px 0;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
    .boxText {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        width: 70%;
      }
      @media only screen and (max-width: 480px) {
        width: 95%;
        padding: 5%;
      }
      width: 45%;
      height: fit-content;
      background: #fff;
      padding: 2% 2% 10% 2%;
      .footerList {
        width: 100%;
        p {
          text-align: justify;
          color: #575757;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0px;
          font-weight: 400;
        }
        .Loader {
          justify-content: center;
          padding: 100px 0;
          display: flex;
        }
      }
    }
    .boxOrange {
      position: absolute;
      margin-top: -370px;
      z-index: -2;
      position: absolute;
      height: 50vh;
      left: 0;
      background-color: #f89000;
      width: 55%;
    }
    .lineBlue {
      @media only screen and (max-width: 480px) {
        margin: -5% 20% 0 20%;
      }
      position: absolute;
      margin-top: -80px;
      margin-left: 10%;
      width: 50%;
      height: 10px;
      background-color: #004291;
      border-radius: 100px;
    }
  }
  .boxSobre {
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      width: 100%;
      margin: auto;
      grid-template-columns: 50% 50%;
      padding: 10% 5%;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    padding: 10% 15%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    .firstBox {
      width: 100%;
      height: 310px;
      padding: 10% 0 0 15%;
      background-color: #fff;
      border-color: #f5f5f5;
      border-width: 10px;
      border-style: solid;

      .headerText {
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 30px;
        letter-spacing: 0px;
        font-weight: bold;
        text-align: left;
        margin: 0%;
        line-height: 100%;
      }
      .footerText {
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-right: 10%;
        margin-top: 10%;
      }
    }
    .secondBox {
      width: 100%;
      height: 310px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-color: #f89000;
      border-width: 2%;
      border-style: solid;
      img {
        width: 60%;
        height: 60%;
        object-fit: fit;
      }
    }
    .trirdBox {
      width: 100%;
      height: 310px;
      padding: 10% 0 0 15%;
      background-color: #fff;
      border-color: #f5f5f5;
      border-width: 10px;
      border-style: solid;

      .headerText {
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 30px;
        letter-spacing: 0px;
        font-weight: bold;
        text-align: left;
        margin: 0%;
        line-height: 100%;
      }
      .footerText {
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-right: 10%;
        margin-top: 10%;
      }
    }
    .fourthBox {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        grid-column: 1;
        grid-row: 2;
      }
      width: 100%;
      height: 310px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-color: #f89000;
      border-width: 2%;
      border-style: solid;
      img {
        width: 60%;
        height: 60%;
        object-fit: fit;
      }
    }
    .fifthBox {
      width: 100%;
      height: 310px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-color: #f89000;
      border-width: 2%;
      border-style: solid;
      img {
        width: 60%;
        height: 60%;
        object-fit: fit;
      }
    }
    .sixthBox {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        grid-column: 1;
        grid-row: 3;
      }
      @media only screen and (max-width: 480px) {
        grid-row: 5;
      }
      width: 100%;
      height: 310px;
      padding: 10% 0 0 15%;
      background-color: #fff;
      border-color: #f5f5f5;
      border-width: 10px;
      border-style: solid;

      .headerText {
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 30px;
        letter-spacing: 0px;
        font-weight: bold;
        text-align: left;
        margin: 0%;
        line-height: 100%;
      }
      .footerText {
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-right: 10%;
        margin-top: 10%;
      }
    }
    .seventhBox {
      width: 100%;
      height: 310px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-color: #f89000;
      border-width: 2%;
      border-style: solid;
      img {
        width: 60%;
        height: 60%;
        object-fit: fit;
      }
    }
    .eighthBox {
      @media only screen and (max-width: 480px) {
        grid-row: 7;
      }
      width: 100%;
      height: 310px;
      padding: 10% 0 0 15%;
      background-color: #fff;
      border-color: #f5f5f5;
      border-width: 10px;
      border-style: solid;

      .headerText {
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 28px;
        letter-spacing: 0px;
        font-weight: bold;
        text-align: left;
        margin: 0%;
        line-height: 100%;
      }
      .footerText {
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-right: 10%;
        margin-top: 10%;
      }
    }
  }
`;
