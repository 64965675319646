import React, { useState } from 'react';
import { FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';
import { Container } from './styles';
import { Spin as Hamburger } from 'hamburger-react';

export default function Header() {
  // state Hamburguer
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Container>
        <div className="logo">
          <a href="/" className="LogoIcon">
            <img
              src={require('../../assets/marca/LogoIcon.svg')}
              alt="LogoIcon"
            />
          </a>

          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        <div className={`midHeader ${isOpen ? 'mobile' : ''}`}>
          <div className="contato">
            <div className="tel">
              <a href="tel: +554135324224">
                <FiPhone className="FiPhone" />
                (41) 3532-4224
              </a>
              <span>|</span>
              <a href="tel: +5541991255026">(41) 99125-5026 (Financeiro)</a>
            </div>
            <div className="line" />
            <div className="mail">
              <a href="mailto: contato@prmempreendimentos.com.br">
                <GoMail className="GoMail" />
                contato@prmempreendimentos.com.br
              </a>
            </div>
          </div>
          <nav>
            <ul>
              <li>
                <a href="/lancamentos">Lançamentos</a>
              </li>
              <li>
                <a href="/imoveis">Imóveis à venda</a>
              </li>
              <li>
                <a href="/entregues">Entregues</a>
              </li>
              {/* <li>
                <a href="/#depoimentos">Depoimentos</a>
              </li> */}
              <li>
                <a href="/quem-somos">Institucional</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a href="/trabalhe-conosco">Trabalhe conosco</a>
              </li>
              <li>
                <a href="/contato">Contato</a>
              </li>
            </ul>
          </nav>
        </div>
        <a href="https://api.whatsapp.com/send?phone=5541991466488&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20saber%20sobre%20">
          <div className="Whats" />
        </a>
      </Container>
    </>
  );
}
