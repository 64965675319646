import styled from 'styled-components';
import banner from '../../../assets/img/bgLancamento.png';

export const Top = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 90px;
  }
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10% 0 0 0;
  .banner {
    position: absolute;
    width: 100%;
    height: 400px;
    background: #f89000;
    top: 0;
    left: 0;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(248, 144, 0, 0.68);
      background: url(${banner});
      opacity: 0.1;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
    }
    @media only screen and (max-width: 1400px) {
      height: 350px;
      }
      @media only screen and (max-width: 1000px) {
        height: 300px;
      }
  }
  .content {
    position: relative;
    .Text {
      @media only screen and (max-width: 768px) {
        padding: 5%;
      }
      padding-left: 15%;
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
      }
      .Line {
        margin: 20px 0;
        width: 80px;
        height: 10px;
        background-color: #004291;
        border-radius: 100px;
      }
      .footerList {
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 22px;
        }
        color: #fff;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 90%;
        margin: 0;
      }
    }
  }
`;

export const Content = styled.div`
  width: 70%;
  margin: auto;
  padding: 100px 0;
  .singlePost {
    display: flex;
    flex-direction: column;
    h1 {
      color: #313131;
      display: flex;
      opacity: 1;
      text-decoration: none;
      font-size: 24px;
      letter-spacing: 0px;
      position: relative;
      font-weight: bold;
      line-height: 120%;
      margin: 0;
    }
    .Conteudo {
      h2 {
        margin: 50px 0 0 0;
        color: #313131;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 20px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 35px;
      }
      .data {
        color: #313131;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 100%;
        margin: 25px 0;
      }
      p {
        color: #313131;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 35px;
        margin-bottom: 15px;
        text-align: left;
        display: flex;
        flex-direction: column;
        span {
          color: #313131;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 20px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          line-height: 35px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 50px 0;
        width: 100%;
        height: 350px;
      }

      p {
        color: #313131;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 35px;
        margin-bottom: 15px;
        text-align: left;
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
  .Loader {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
export const Cards = styled.div`
  .content {
    position: relative;
    .Text {
      @media only screen and (max-width: 768px) {
        padding: 5%;
      }
      padding-left: 15%;
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
      }
      .Line {
        margin: 20px 0;
        width: 80px;
        height: 10px;
        background-color: #f89000;
        border-radius: 100px;
      }
      .footerList {
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 22px;
        }
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        line-height: 90%;
        margin: 0;
      }
    }
  }
  .tabelaCards {
    position: relative;
    z-index: 1;
    background-color: #fff;
    margin: 5% 15%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
    justify-content: center;
    .boxBlog {
      width: 100%;
      .headerBox {
        .avatar {
          width: 100%;
          padding: 10px;
          height: 200px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .titleBlog {
          color: #004291;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 22px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          line-height: 100%;
          margin: 0;
          padding: 10px;
          height: 90px;
        }
        .content {
          color: #313131;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 10px;
          height: 250px;
        }
      }
      .buttonMore {
        width: 90%;
        height: 35px;
        border-radius: 100px;
        background-color: #004291;
        display: flex;
        margin: 5%;
        transition: all linear 0.3s;
        a {
          color: #fff;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-decoration: none;
          display: flex;
          width: 100%;
          height: 100%;
          margin: auto;
        }
      }
      .buttonMore:hover {
        background-color: #f89000;
      }
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-row-gap: 75px;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 5%;
      margin: 5% 5%;
      grid-row-gap: 75px;
    }
  }
  .Blog {
    text-decoration: none;
    width: 20%;
    height: auto;
    border-radius: 100px;
    background-color: #f89000;
    display: flex;
    transition: all linear 0.3s;
    margin: auto;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    font-size: 26px;
    padding: 5px 5px;
    :hover {
      background-color: #ff741d;
    }
    @media only screen and (max-width: 800px) {
      width: 40%;
    }
    @media only screen and (max-width: 480px) {
      width: 60%;
    }
  }
`;
