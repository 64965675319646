import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';
import api from '../../services/api';

//HTML-PARSER
import ReactHtmlParser from 'react-html-parser';

import { Content } from './styles';

export default function Blog() {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState();

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/posts?_embed').then((response) => {
      setPost(response.data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Content>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Blog</p>
              <div className="Line" />
              <p className="footerList">
                Notícias e curiosidades sobre área de Empreendimentos
              </p>
            </div>
            <Loader
            className="Loader"
            type="Rings"
            color="#004291"
            height={100}
            width={100}
          />
          </div>
        </Content>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <Content>
          <div className="banner">
            <div className="bg" />
          </div>
          <div className="content">
            <div className="Text">
              <p className="headerList">Blog</p>
              <div className="Line" />
              <p className="footerList">
                Notícias e curiosidades sobre área de Empreendimentos
              </p>
            </div>
            <div className="tabelaCards">
              {!!post &&
                post.map((content, index) => (
                  <div className="boxBlog">
                    <a href={`/post/${content.slug}`} className="headerBox">
                      <div className="avatar">
                        <img
                          src={
                            content.featured_media
                              ? content._embedded['wp:featuredmedia']['0']
                                  .source_url
                              : ' '
                          }
                          alt={index}
                        />
                      </div>
                      <p className="titleBlog">
                        {!!content && content.title.rendered}
                      </p>
                      <p className="content">
                        {ReactHtmlParser(!!content && content.excerpt.rendered)}
                      </p>
                    </a>
                    <div className="buttonMore">
                      <a href={`/post/${content.slug}`}>Continuar Lendo</a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Content>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  }
}
