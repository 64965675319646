import React, { useState, useEffect, useRef } from 'react';
// imports de dependencias
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import Lightbox, { Modal, ModalGateway } from 'react-images';
import Loader from 'react-loader-spinner';

import api from '../../services/api';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';
import ademail from '../../services/ademail';

//HTML-PARSER
import ReactHtmlParser from 'react-html-parser';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Content,
  Imagens,
  Localizacao,
  Diferenciais,
  Plantas,
  Mail,
} from './styles';
// imports para estilização
import { GrLocation } from 'react-icons/gr';
import { HiOutlineHome } from 'react-icons/hi';
import { GrImage } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

export default function Empreendimentos({ match }) {
  const banner = useRef();
  const [modalisopen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [empreendimento, setEmpreendimento] = useState(null);
  const [loading, setLoading] = useState(true);

  const [disabledButton, setdisabledButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo é obrigatório'),
      telefone: Yup.string().required('*Campo é obrigatório'),
      mensagem: Yup.string().required('*Campo é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      setdisabledButton(true);

      const body = `
                      <p>Nome: ${values.nome}</p>
                      <p>E-mail: ${values.email}</p>
                      <p>Telefone: ${values.telefone}</p>
                      <p>Mensagem: ${values.mensagem}</p>
                    `;

      const mail = {
        to: 'comercial01@prmempreendimentos.com.br',
        from: values.email,
        as: values.nome,
        subject: 'Novo empreendimento via site - PRM',
        message: body,
      };

      try {
        await ademail.post('/email', mail);

        notification.success({
          message: 'Mensagem enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });
        // Sucesso ao enviar
        setdisabledButton(false);
        resetForm();
      } catch (error) {
        // Erro ao enviar
        setdisabledButton(false);
        notification.error({
          message: 'Não foi possivel concluir o cadastro! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  const images =
    !!empreendimento &&
    empreendimento.acf.galeria_de_imagens.map((foto, index) => ({
      index: index,
      src: foto.sizes.large,
    }));
  const slug = match.params.slug;

  // useEffect
  useEffect(() => {
    setLoading(true);
    api.get(`/wp/v2/empreendimentos?slug=${slug}`).then((response) => {
      setEmpreendimento(response.data[0]);
      setTimeout(100);
      setLoading(false);
    });
  }, [slug]);

  //Separando o valor inteiros
  var resultado = !!empreendimento
    ? empreendimento.acf.valor_promocional_de_apenas.valor.split(',')
    : ' ';

  const bannerSettings = {
    adaptiveHeight: true,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 5000,
  };
  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM Empreendimentos</title>
        </Helmet>
        <Header />
        <Content>
          <div className="Loader">
            <Loader
              className="Loader"
              type="Rings"
              color="#004291"
              height={100}
              width={100}
            />
            <Loader
              className="Loader"
              type="Rings"
              color="#f89000"
              height={100}
              width={100}
            />
          </div>
        </Content>
        <Footer />
        {/* Gateway lightbox */}
        <ModalGateway>
          {modalisopen && (
            <Modal onClose={() => setModalIsOpen(!modalisopen)}>
              <Lightbox views={images} currentIndex={selectedIndex} />
            </Modal>
          )}
        </ModalGateway>
      </>
    );
  } else {
    if (
      empreendimento.acf.tipo[0] === 'Entregue' ||
      empreendimento.acf.tipo[1] === 'Entregue' ||
      empreendimento.acf.tipo[2] === 'Entregue' ||
      empreendimento.acf.tipo[3] === 'Entregue'
    ) {
      return (
        <>
          <Helmet>
            <title>PRM Empreendimentos</title>
          </Helmet>
          <Header />
          <Content>
            <div className="bg">
              <img
                src={
                  !!empreendimento
                    ? empreendimento.acf.imagem_de_capa.sizes.large
                    : ''
                }
                alt="bannerEmpreendimentos"
              ></img>
            </div>
            <div className="infos">
              <div className="logoEmpreendimento">
                <img
                  src={!!empreendimento ? empreendimento.acf.marca : ''}
                  alt={!!empreendimento ? empreendimento.title.rendered : ''}
                ></img>
              </div>
              <div className="sobre">
                <p className="headerInfos">
                  {!!empreendimento && empreendimento.title.rendered}
                </p>
                <div className="middleInfos">
                  <div className="icon">
                    <GrLocation className="GrLocation" />
                  </div>
                  <div className="infoLoc">
                    <p>
                      Localização:{' '}
                      {!!empreendimento && empreendimento.acf.cidade}
                      <br></br>
                      {!!empreendimento && empreendimento.acf.endereco}
                    </p>
                  </div>
                </div>
                <div className="colorBox">
                  {!!empreendimento &&
                    empreendimento.acf.descricoes.map((descricao, index) => (
                      <p key={index} className="text">
                        {descricao.texto}
                      </p>
                    ))}
                </div>
              </div>
            </div>
            <div className="bannerContentEntregue">
              <img
                src={require('../../assets/img/bannerEntregues.jpg')}
                alt="bannerEmpreendimentosContent"
              ></img>
            </div>
            <div className="boxWhite">
              <div className="cardsEntregue">
                <div className="cardGreenEntregue">
                  <p className="headerCardEntregue">Condomínio entregue</p>
                  <p className="middleCardEntregue">100% finalizado</p>
                </div>
                <div className="cardBlueEntregue">
                  <p className="textHeaderEntregue">E muitos</p>
                  <p className="textContentEntregue">sonhos realizados</p>
                </div>
              </div>
            </div>
          </Content>
          <Mail>
            <div className="Text">
              <div className="headerImg">
                <GoMail className="GoMail" />
                <p className="headerList">Dúvidas?</p>
              </div>
              <div className="Line" />
            </div>
            <div className="boxForm">
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nome}
                />
                {formik.touched.nome && formik.errors.nome ? (
                  <span className="erro">{formik.errors.nome}</span>
                ) : null}
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className="erro">{formik.errors.email}</span>
                ) : null}
                <input
                  type="tel"
                  name="telefone"
                  mask={
                    formik.values.telefone.length <= 14
                      ? '(99) 9999-9999?'
                      : '(99) 99999-9999'
                  }
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  maskChar={null}
                  placeholder="Telefone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telefone}
                />
                {formik.touched.telefone && formik.errors.telefone ? (
                  <span>{formik.errors.telefone}</span>
                ) : null}
                <textarea
                  placeholder="Mensagem"
                  name="mensagem"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mensagem}
                />
                {formik.touched.mensagem && formik.errors.mensagem ? (
                  <span className="erro">{formik.errors.mensagem}</span>
                ) : null}
                <div className="btn">
                  <button
                    type="submit"
                    className={disabledButton ? 'enable' : ''}
                    disabled={disabledButton ? 'disabled' : ''}
                  >
                    ENVIAR
                  </button>
                </div>
              </form>
            </div>
          </Mail>
          <Imagens>
            <div className="Text">
              <div className="headerImg">
                <GrImage className="GrImage" />
                <p className="headerList">Imagens</p>
              </div>
              <div className="Line" />
            </div>
            <div className="imagens">
              {!!empreendimento &&
                empreendimento.acf.galeria_de_imagens.map((foto, index) => (
                  <div className="img" key={index}>
                    <img
                      src={foto.sizes.medium}
                      alt={index}
                      onClick={() => {
                        setModalIsOpen(!modalisopen);
                        setSelectedIndex(index);
                      }}
                    />
                  </div>
                ))}
            </div>
          </Imagens>
          {!!empreendimento && !!empreendimento.acf.plantas ? (
            <Plantas>
              <div className="Text">
                <div className="headerPlantas">
                  <div className="iconPlant">
                    <img
                      src={require('../../assets/icon/IconPlant.svg')}
                      alt="iconPlant"
                    ></img>
                  </div>
                  <p className="headerList">Plantas</p>
                </div>
                <div className="Line" />
              </div>
              <div className="sliderPlantas">
                <RiArrowLeftSLine
                  className="RiArrowLeftSLine"
                  onClick={() => banner.current.prev()}
                />
                <div className="boxWhite">
                  <Carousel
                    ref={banner}
                    {...bannerSettings}
                    className="Carousel"
                  >
                    {!!empreendimento &&
                      empreendimento.acf.plantas.map((planta, index) => (
                        <div className="slider" key={index}>
                          <div className="plantaImg">
                            <img src={planta.imagem.sizes.medium} alt={index} />
                          </div>
                          <div className="description">
                            <p>{planta.descricao}</p>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </div>
                <RiArrowRightSLine
                  className="RiArrowRightSLine"
                  onClick={() => banner.current.next()}
                />
              </div>
            </Plantas>
          ) : (
            ' '
          )}
          {!!empreendimento && !!empreendimento.acf.plantas ? (
            <Localizacao>
              <div className="location">
                <div className="headerLoc">
                  <GrLocation className="GrLocation" />
                  <p className="headerList">Localização</p>
                </div>
                <div className="Line" />
              </div>
              <div className="iframe">
                {ReactHtmlParser(
                  !!empreendimento && empreendimento.acf.localizacao
                )}
              </div>
              <div className="description">
                <div className="icon">
                  <GrLocation className="GrLocation" />
                </div>
                <div className="infoLoc">
                  <p>
                    Localização: {!!empreendimento && empreendimento.acf.cidade}
                    <br></br>
                    {!!empreendimento && empreendimento.acf.endereco}
                  </p>
                </div>
              </div>
            </Localizacao>
          ) : (
            ' '
          )}
          <Diferenciais>
            <div className="Text">
              <div className="headerDif">
                <div className="IconStar">
                  <img
                    src={require('../../assets/icon/IconStar.svg')}
                    alt="IconStar"
                  ></img>
                </div>
                <p className="headerList">Diferenciais do residencial</p>
              </div>
              <div className="Line" />
            </div>
            {!!empreendimento &&
              empreendimento.acf.diferenciais_do_condominio.map(
                (texto, index) => (
                  <div className="boxWhite">
                    <div className="topic">
                      <FaCheck className="FaCheck" />
                      <p>{texto.diferencial}</p>
                    </div>
                  </div>
                )
              )}
            <div className="boxBlue">
              <div className="iconHappy">
                <img
                  src={require('../../assets/icon/IconLove.svg')}
                  alt="IconLove"
                />
              </div>
              <div className="Text">
                <div className="Header">
                  <p>É o imóvel dos seus sonhos?</p>
                </div>
                <div className="Info">
                  <p>
                    Entre em contato para tirar mais duvidas e fechar negócio!
                  </p>
                </div>
                <div className="Footer">
                  <div className="buttonClick">
                    <a href="/contato">Clique aqui</a>
                  </div>
                  <p>ou</p>
                  <div className="buttonWhats">
                    <FaWhatsapp className="FaWhatsapp" />
                    <a href="https://api.whatsapp.com/send?phone=seunumerodetelefone&text=Olá, tenho interesse no Residencial Colina Verde">
                      Mande um Whats
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Diferenciais>
          <Footer />
          {/* Gateway lightbox */}
          <ModalGateway>
            {modalisopen && (
              <Modal onClose={() => setModalIsOpen(!modalisopen)}>
                <Lightbox views={images} currentIndex={selectedIndex} />
              </Modal>
            )}
          </ModalGateway>
        </>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>PRM Empreendimentos</title>
          </Helmet>
          <Header />
          <Content>
            <div className="bg">
              <img
                src={
                  !!empreendimento
                    ? empreendimento.acf.imagem_de_capa.sizes.large
                    : ''
                }
                alt="bannerEmpreendimentos"
              ></img>
            </div>
            <div className="infos">
              <div className="logoEmpreendimento">
                <img
                  src={!!empreendimento ? empreendimento.acf.marca : ''}
                  alt={!!empreendimento ? empreendimento.title.rendered : ''}
                ></img>
              </div>
              <div className="sobre">
                <p className="headerInfos">
                  {!!empreendimento && empreendimento.title.rendered}
                </p>
                <div className="middleInfos">
                  <div className="icon">
                    <GrLocation className="GrLocation" />
                  </div>
                  <div className="infoLoc">
                    <p>
                      Localização:{' '}
                      {!!empreendimento && empreendimento.acf.cidade}
                      <br></br>
                      {!!empreendimento && empreendimento.acf.endereco}
                    </p>
                  </div>
                </div>
                <div className="colorBox">
                  {!!empreendimento &&
                    empreendimento.acf.descricoes.map((descricao, index) => (
                      <p key={index} className="text">
                        {descricao.texto}
                      </p>
                    ))}
                </div>
              </div>
            </div>

            <div className="bannerContent">
              <img
                src={require('../../assets/img/bannerEmpreendimentosContent.png')}
                alt="bannerEmpreendimentosContent"
              ></img>
            </div>
            <div className="boxWhite">
              <div className="text">
                <p className="textHeaderOrange">
                  Saia do aluguel, conquiste o sonho da{' '}
                  <span>casa própria!</span>
                </p>
              </div>
              <div className="boxGreyOne">
                <div className="firstCollum">
                  <HiOutlineHome className="HiOutlineHome" />
                </div>
                <div className="secondCollum">
                  <p className="title">
                    {!!empreendimento &&
                      empreendimento.acf.saia_do_aluguel
                        .bloco_icone_casa_laranja.titulo}
                  </p>
                  <p className="descriptions">
                    {!!empreendimento &&
                      empreendimento.acf.saia_do_aluguel
                        .bloco_icone_casa_laranja.texto}
                  </p>
                </div>
              </div>
              {!!empreendimento &&
              !!empreendimento.acf.saia_do_aluguel
                .bloco_icone_casa_verde_e_amarela.texto ? (
                <div className="boxGreyTwo">
                  <div className="firstCollum">
                    <img
                      src={require('../../assets/img/minhacasa.svg')}
                      alt="minhacasa"
                    />
                  </div>
                  <div className="secondCollum">
                    <p>
                      {!!empreendimento &&
                        empreendimento.acf.saia_do_aluguel
                          .bloco_icone_casa_verde_e_amarela.texto}
                    </p>
                  </div>
                </div>
              ) : (
                ' '
              )}
              <div className="cards">
                <div className="cardBlue">
                  <p className="textHeader">Condições</p>
                  <p className="textContent">
                    <span>facilitadas</span> para você <br></br>adquirir o seu
                    imóvel!
                  </p>
                </div>
                {!!empreendimento &&
                empreendimento.acf.valor_promocional_de_apenas
                  .texto_condicoes ? (
                  <div className="cardGreen">
                    <p className="headerCard">Valor promocional de apenas</p>
                    <p className="middleCard">
                      <span>R$</span>
                      {resultado[0]}
                      <span>,{resultado[1]}</span>
                    </p>
                    <p className="footerCard">
                      {ReactHtmlParser(
                        !!empreendimento &&
                          empreendimento.acf.valor_promocional_de_apenas
                            .texto_condicoes
                      )}
                    </p>
                    <p className="legendaCard">
                      {!!empreendimento
                        ? empreendimento.acf.valor_promocional_de_apenas
                            .observacoes
                        : ' '}
                    </p>
                  </div>
                ) : (
                  ' '
                )}
              </div>
            </div>
          </Content>
          <Mail>
            <div className="Text">
              <div className="headerImg">
                <GoMail className="GoMail" />
                <p className="headerList">Dúvidas?</p>
              </div>
              <div className="Line" />
            </div>
            <div className="boxForm">
              <form onSubmit={formik.handleSubmit}>
                <div className="inputForm">
                  <input
                    type="text"
                    name="nome"
                    placeholder="Nome"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span className="erro">{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div className="inputForm">
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span className="erro">{formik.errors.email}</span>
                  ) : null}
                </div>
                <div className="inputForm">
                  <input
                    type="tel"
                    name="telefone"
                    mask={
                      formik.values.telefone.length <= 14
                        ? '(99) 9999-9999?'
                        : '(99) 99999-9999'
                    }
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    maskChar={null}
                    placeholder="Telefone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefone}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                </div>
                <div className="inputText">
                <textarea
                  placeholder="Mensagem"
                  name="mensagem"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mensagem}
                />
                {formik.touched.mensagem && formik.errors.mensagem ? (
                  <span className="erro">{formik.errors.mensagem}</span>
                ) : null}
                </div>
                <div className="btn">
                  <button
                    type="submit"
                    className={disabledButton ? 'enable' : ''}
                    disabled={disabledButton ? 'disabled' : ''}
                  >
                    ENVIAR
                  </button>
                </div>
              </form>
            </div>
          </Mail>
          <Imagens>
            <div className="Text">
              <div className="headerImg">
                <GrImage className="GrImage" />
                <p className="headerList">Imagens</p>
              </div>
              <div className="Line" />
            </div>
            <div className="imagens">
              {!!empreendimento &&
                empreendimento.acf.galeria_de_imagens.map((foto, index) => (
                  <div className="img" key={index}>
                    <img
                      src={foto.sizes.medium}
                      alt={index}
                      onClick={() => {
                        setModalIsOpen(!modalisopen);
                        setSelectedIndex(index);
                      }}
                    />
                  </div>
                ))}
            </div>
          </Imagens>
          {!!empreendimento && !!empreendimento.acf.plantas ? (
            <Plantas>
              <div className="Text">
                <div className="headerPlantas">
                  <div className="iconPlant">
                    <img
                      src={require('../../assets/icon/IconPlant.svg')}
                      alt="iconPlant"
                    ></img>
                  </div>
                  <p className="headerList">Plantas</p>
                </div>
                <div className="Line" />
              </div>
              <div className="sliderPlantas">
                <RiArrowLeftSLine
                  className="RiArrowLeftSLine"
                  onClick={() => banner.current.prev()}
                />
                <div className="boxWhite">
                  <Carousel
                    ref={banner}
                    {...bannerSettings}
                    className="Carousel"
                  >
                    {!!empreendimento &&
                      empreendimento.acf.plantas.map((planta, index) => (
                        <div className="slider" key={index}>
                          <div className="plantaImg">
                            <img src={planta.imagem.sizes.medium} alt={index} />
                          </div>
                          <div className="description">
                            <p>{planta.descricao}</p>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </div>
                <RiArrowRightSLine
                  className="RiArrowRightSLine"
                  onClick={() => banner.current.next()}
                />
              </div>
            </Plantas>
          ) : (
            ' '
          )}
          {!!empreendimento && !!empreendimento.acf.plantas ? (
            <Localizacao>
              <div className="location">
                <div className="headerLoc">
                  <GrLocation className="GrLocation" />
                  <p className="headerList">Localização</p>
                </div>
                <div className="Line" />
              </div>
              <div className="iframe">
                {ReactHtmlParser(
                  !!empreendimento && empreendimento.acf.localizacao
                )}
              </div>
              <div className="description">
                <div className="icon">
                  <GrLocation className="GrLocation" />
                </div>
                <div className="infoLoc">
                  <p>
                    Localização: {!!empreendimento && empreendimento.acf.cidade}
                    <br></br>
                    {!!empreendimento && empreendimento.acf.endereco}
                  </p>
                </div>
              </div>
            </Localizacao>
          ) : (
            ' '
          )}
          <Diferenciais>
            <div className="Text">
              <div className="headerDif">
                <div className="IconStar">
                  <img
                    src={require('../../assets/icon/IconStar.svg')}
                    alt="IconStar"
                  ></img>
                </div>
                <p className="headerList">Diferenciais do residencial</p>
              </div>
              <div className="Line" />
            </div>
            {!!empreendimento &&
              empreendimento.acf.diferenciais_do_condominio.map(
                (texto, index) => (
                  <div className="boxWhite">
                    <div className="topic">
                      <FaCheck className="FaCheck" />
                      <p>{texto.diferencial}</p>
                    </div>
                  </div>
                )
              )}
            <div className="boxBlue">
              <div className="iconHappy">
                <img
                  src={require('../../assets/icon/IconLove.svg')}
                  alt="IconLove"
                />
              </div>
              <div className="Text">
                <div className="Header">
                  <p>É o imóvel dos seus sonhos?</p>
                </div>
                <div className="Info">
                  <p>
                    Entre em contato para tirar mais duvidas e fechar negócio!
                  </p>
                </div>
                <div className="Footer">
                  <div className="buttonClick">
                    <a href="/contato">Clique aqui</a>
                  </div>
                  <p>ou</p>
                  <div className="buttonWhats">
                    <FaWhatsapp className="FaWhatsapp" />
                    <a href="https://api.whatsapp.com/send?phone=seunumerodetelefone&text=Olá, tenho interesse no Residencial Colina Verde">
                      Mande um Whats
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Diferenciais>
          <Footer />
          {/* Gateway lightbox */}
          <ModalGateway>
            {modalisopen && (
              <Modal onClose={() => setModalIsOpen(!modalisopen)}>
                <Lightbox views={images} currentIndex={selectedIndex} />
              </Modal>
            )}
          </ModalGateway>
        </>
      );
    }
  }
}
