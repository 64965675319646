import styled from 'styled-components';

export const Content = styled.div`
  .Loader {
    display: flex;
    justify-content: center;
    padding: 100px 0;
  }
  .bg {
    width: 100%;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .infos {
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      grid-template-columns: 25% 60%;
    }
    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    display: grid;
    grid-template-columns: 25% 40%;
    grid-gap: 5%;
    justify-content: center;
    align-content: center;
    margin: 5% 0;
    .logoEmpreendimento {
      @media only screen and (max-width: 480px) {
        padding: 5%;
      }
      width: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sobre {
      margin-bottom: 5%;
      .headerInfos {
        @media only screen and (max-width: 480px) {
          padding: 5%;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 35px;
        letter-spacing: 0px;
        font-weight: bold;
        margin: 0;
      }
      .middleInfos {
        display: flex;
        flex-direction: row;
        .icon {
          @media only screen and (max-width: 480px) {
            padding: 5%;
          }
          display: flex;
          .GrLocation {
            font-size: 300%;
            path {
              stroke: #f89000;
            }
          }
        }
        .infoLoc {
          display: flex;
          flex-direction: column;
          margin-left: 2%;
          p {
            color: #575757;
            display: flex;
            opacity: 1;
            text-decoration: none;
            font-size: 16px;
            letter-spacing: 0px;
            position: relative;
            font-weight: 400;
            margin: 0;
          }
        }
      }
      .colorBox {
        @media only screen and (min-width: 480px) and (max-width: 768px) {
          grid-template-columns: 24% 24% 24% 24%;
          grid-column-gap: 1%;
        }
        @media only screen and (max-width: 480px) {
          grid-template-columns: 45% 45%;
          justify-content: space-evenly;
        }
        margin-top: 1%;
        display: grid;
        grid-template-columns: 32.6% 32.7% 32.7%;
        grid-row-gap: 5px;
        grid-column-gap: 1%;
        justify-content: left;
        align-content: center;
        .text {
          height: 50px;
          line-height: 100%;
          text-align: center;
          color: #fff;
          font-weight: bold;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0px;
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 0;
          padding: 5%;

          :nth-of-type(1) {
            background-color: #f89000;
          }
          :nth-of-type(2) {
            background: #ff0d58;
          }
          :nth-of-type(3) {
            background: #51a31d;
          }
          :nth-of-type(4) {
            background: #0f9191;
          }
          :nth-of-type(5) {
            background-color: #f89000;
          }
          :nth-of-type(6) {
            background: #ff0d58;
          }
        }
      }
    }
  }
  .bannerContent {
    @media only screen and (max-width: 768px) {
      position: relative;
      height: auto;
      width: 100%;
      margin: auto;
      align-items: center;
    }
    width: 55%;
    height: 800px;
    position: absolute;
    right: 0;
    z-index: 0;
    margin-top: -40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .boxWhite {
    background-color: #fff;
    padding-top: 5%;
    width: 100%;
    height: fit-content;
    .text {
      @media only screen and (max-width: 768px) {
        max-width: unset;
        margin: 5%;
      }
      margin-left: 15%;
      max-width: 30%;
      margin-bottom: 5%;
      .textHeaderOrange {
        color: #f89710;
        opacity: 1;
        text-decoration: none;
        font-size: 40px;
        letter-spacing: 0px;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
      span {
        color: #004291;
      }
    }
    .boxGreyOne {
      @media only screen and (max-width: 768px) {
        grid-template-columns: 20% 70%;
        width: 90%;
        margin: 5%;
        padding: unset;
        grid-gap: 10px;
        height: 120px;
      }
      margin-left: 15%;
      width: 29%;
      height: 120px;
      background-color: #f5f5f5;
      margin-bottom: 1%;
      display: grid;
      grid-template-columns: 30% 70%;
      align-content: center;
      justify-content: center;
      .firstCollum {
        width: 100%;
        margin: auto;
        justify-content: center;
        display: flex;
        align-items: center;
        .HiOutlineHome {
          font-size: 550%;
          display: flex;
          path {
            stroke: #f89000;
          }
        }
      }
      .secondCollum {
        display: flex;
        flex-direction: column;
        margin-left: 3%;
        .title {
          padding-top: 15px;
          color: #575757;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          margin: 0;
        }
        .discriptions {
          color: #575757;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          margin: 0;
        }
      }
    }
    .boxGreyTwo {
      @media only screen and (max-width: 768px) {
        grid-template-columns: 20% 70%;
        width: 90%;
        margin: 5%;
        padding: unset;
        grid-gap: 10px;
        height: 140px;
      }
      margin-left: 15%;
      width: 29%;
      height: 120px;
      background-color: #f5f5f5;
      margin-bottom: 1%;
      display: grid;
      grid-template-columns: 30% 70%;
      align-content: center;
      justify-content: center;
      .firstCollum {
        padding: 10%;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .secondCollum {
        display: flex;
        align-items: center;
        margin-right: 3%;
        p {
          color: #575757;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 15px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          margin: 0;
          line-height: 125%;
        }
      }
    }
    .cards {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        width: 90%;
        margin: 10% 5% 10% 5%;
      }
      @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        width: 90%;
        margin: auto;
      }
      z-index: 1;
      width: 70%;
      padding-bottom: 10%;
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 10% 15% 10% 15%;
      .cardBlue {
        @media only screen and (max-width: 480px) {
          height: 200px;
        }
        z-index: 1;
        background-color: #004291;
        padding: 10%;
        .textHeader {
          @media only screen and (max-width: 480px) {
            font-size: 28px;
          }
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 36px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
        }
        .textContent {
          @media only screen and (max-width: 480px) {
            font-size: 28px;
          }
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 36px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
          span {
            color: #f89710;
          }
        }
      }
      .cardGreen {
        @media only screen and (max-width: 480px) {
          height: 200px;
          padding: 5%;
        }
        z-index: 1;
        background-color: #51a31d;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        .headerCard {
          @media only screen and (max-width: 480px) {
            padding: unset;
          }
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 22px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
          text-transform: uppercase;
          padding: 3%;
        }
        .middleCard {
          @media only screen and (max-width: 480px) {
            font-size: 45px;
          }
          color: #fff845;
          opacity: 1;
          text-decoration: none;
          font-size: 55px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
          span {
            font-size: 30px;
            font-weight: 400;
            justify-content: top;
          }
        }
        .footerCard {
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 20px;
          letter-spacing: 0px;
          font-weight: 400;
          margin: 0;
          line-height: 100%;
          span {
            font-weight: bold;
          }
        }
        .legendaCard {
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 0px;
          font-weight: 400;
          margin: 0;
          line-height: 100%;
        }
      }
    }
    .cardsEntregue {
      @media only screen and (min-width: 480px) and (max-width: 768px) {
        width: 90%;
        margin: 10% 5% 10% 5%;
      }
      @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
        width: 90%;
        margin: auto;
      }
      margin-left: 15%;
      z-index: 2;
      width: 35%;
      padding-bottom: 10%;
      display: flex;
      flex-direction: column;

      .cardGreenEntregue {
        @media only screen and (max-width: 480px) {
          padding: 5%;
        }
        z-index: 1;
        background-color: #51a31d;
        padding: 10%;
        display: flex;
        height: 150px;
        flex-direction: column;
        .headerCardEntregue {
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 22px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
          text-transform: uppercase;
          padding: 3% 3% 0 3%;
        }
        .middleCardEntregue {
          @media only screen and (max-width: 480px) {
            font-size: 45px;
          }
          color: #fff845;
          opacity: 1;
          text-decoration: none;
          font-size: 35px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
          padding-left: 3%;
        }
      }
      .cardBlueEntregue {
        z-index: 1;
        background-color: #004291;
        padding: 10%;
        height: 150px;
        .textHeaderEntregue {
          @media only screen and (max-width: 480px) {
            font-size: 28px;
          }
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 36px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
        }
        .textContentEntregue {
          @media only screen and (max-width: 480px) {
            font-size: 28px;
          }
          color: #fff;
          opacity: 1;
          text-decoration: none;
          font-size: 36px;
          letter-spacing: 0px;
          font-weight: bold;
          margin: 0;
          color: #f89710;
          line-height: 100%;
        }
      }
    }
  }
  .bannerContentEntregue {
    @media only screen and (max-width: 768px) {
      position: relative;
      height: auto;
      width: 100%;
      margin: auto;
      align-items: center;
    }
    width: 55%;
    height: 450px;
    position: absolute;
    right: 0;
    z-index: 0;
    margin-top: -40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const Imagens = styled.div`
  padding-bottom: 10%;
  .Text {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerImg {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .GrImage {
        font-size: 300%;
        margin-right: 2%;
        path {
          stroke: #f89000;
        }
      }
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
    }
    .Line {
      @media only screen and (max-width: 480px) {
        margin-left: 15%;
      }
      margin: 10px 210px;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
  }
  .imagens {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    .img {
      transition: transform 0.7s;
      cursor: pointer;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 450px) {
        height: 150px;
      }
    }
    .img:hover {
      transform: scale(1.05);
    }
    @media (max-width: 450px) {
      grid-template-columns: 50% 50%;
    }
  }
`;
export const Plantas = styled.div`
  padding-bottom: 10%;
  .slick-slide,
  .ant-carousel,
  .slick-slider,
  .slick-track,
  .slick-list,
  .slick-active,
  .slick-current {
    height: 100%;
    width: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
    }
  }

  .Text {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerPlantas {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .iconPlant {
        @media only screen and (max-width: 480px) {
          width: 12%;
        }
        width: 4%;
        margin-right: 2%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
    }
    .Line {
      @media only screen and (max-width: 480px) {
        margin-left: 15%;
      }
      margin: 10px 190px;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
  }
  .sliderPlantas {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    .RiArrowLeftSLine {
      font-size: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      transition: transform 0.5s;
      path:nth-of-type(2) {
        fill: #f89000;
      }
    }
    .RiArrowLeftSLine:hover {
      transform: scale(1.1);
    }
    .RiArrowLeftSLine:active {
      path:nth-of-type(2) {
        fill: #ff741d;
      }
    }
    .boxWhite {
      @media only screen and (max-width: 480px) {
        height: 450px;
      }
      background: #fff;
      width: 100%;
      height: fit-content;
      margin: auto;
      .Carousel {
        .slider {
          .plantaImg {
            @media only screen and (max-width: 480px) {
              height: 300px;
            }
            padding: 5%;
            width: 95%;
            height: 425px;
            display: flex;
            margin: auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .description {
            justify-content: center;
            display: flex;
            padding-bottom: 5%;
            max-width: 65%;
            margin: auto;
            p {
              @media only screen and (max-width: 480px) {
                font-size: 18px;
              }
              text-align: center;
              color: #575757;
              display: flex;
              opacity: 1;
              text-decoration: none;
              font-size: 24px;
              letter-spacing: 0px;
              position: relative;
              font-weight: 400;
              margin: 0;
            }
          }
        }
      }
    }
    .RiArrowRightSLine {
      font-size: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      transition: transform 0.5s;
      path:nth-of-type(2) {
        fill: #f89000;
      }
    }
    .RiArrowRightSLine:hover {
      transform: scale(1.1);
    }
    .RiArrowRightSLine:active {
      path:nth-of-type(2) {
        fill: #ff741d;
      }
    }
  }
`;
export const Localizacao = styled.div`
  background-color: #fff;
  padding-bottom: 10%;
  padding-top: 5%;
  .location {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerLoc {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .GrLocation {
        @media only screen and (max-width: 480px) {
          font-size: 300%;
        }
        font-size: 350%;
        margin-right: 2%;
        path {
          stroke: #f89000;
        }
      }
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 60px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
    }
    .Line {
      @media only screen and (max-width: 480px) {
        margin-left: 15%;
      }
      margin: 10px 305px;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
    }
  }
  .iframe {
    padding-top: 3%;
    justify-content: center;
    margin: auto;
    display: flex;
    iframe {
      width: 70%;
    }
  }
  .description {
    @media only screen and (max-width: 480px) {
      padding: 5%;
    }
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    justify-content: center;
    .icon {
      display: flex;
      .GrLocation {
        font-size: 300%;
        path {
          stroke: #f89000;
        }
      }
    }
    .infoLoc {
      display: flex;
      flex-direction: column;
      margin-left: 2%;
      p {
        color: #575757;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: 0px;
        position: relative;
        font-weight: 400;
        margin: 0;
      }
    }
  }
`;
export const Diferenciais = styled.div`
  padding-bottom: 5%;
  padding-top: 5%;
  .Text {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    padding-left: 15%;
    .headerDif {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .IconStar {
        @media only screen and (max-width: 480px) {
          margin-right: 15%;
        }
        width: 4%;
        margin-right: 2%;
        img {
          @media only screen and (max-width: 480px) {
            width: 55px;
          }
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 50px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
    }
    .Line {
      @media only screen and (min-width: 480px) and (max-width: 685px) {
        margin: 0px 37%;
      }
      @media only screen and (max-width: 480px) {
        margin-left: 20%;
        margin-top: 1%;
      }
      margin: 0px 600px;
      width: 80px;
      height: 10px;
      background-color: #f89000;
      border-radius: 100px;
      margin-bottom: 5%;
    }
  }
  .boxWhite {
    @media only screen and (max-width: 480px) {
      width: 90%;
      margin: 1% 5%;
    }
    height: fit-content;
    width: 70%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 1%;
    margin: 1% 15%;
    .topic {
      display: flex;
      flex-direction: row;
      justify-content: left;
      width: 100%;
      .FaCheck {
        font-size: 150%;
        margin-right: 2%;
        path {
          fill: #68de76;
        }
      }
      p {
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 20px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
        text-transform: uppercase;
      }
    }
  }
  .boxBlue {
    @media only screen and (max-width: 480px) {
      margin: 5%;
      width: 90%;
      grid-template-columns: 1fr;
    }
    background-color: #004291;
    width: 70%;
    margin: 5% 15% 0 15%;
    padding: 3%;
    height: fit-content;
    display: grid;
    grid-template-columns: 20% 60%;
    justify-content: center;
    .iconHappy {
      @media only screen and (max-width: 480px) {
        width: 35%;
      }
      display: flex;
      width: 70%;
      margin: auto;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .Text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      padding: 0;
      .Header {
        p {
          @media only screen and (max-width: 480px) {
            font-size: 25px;
          }
          color: #f7a418;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 36px;
          letter-spacing: 0px;
          position: relative;
          font-weight: bold;
          margin: 0;
          line-height: 100%;
        }
      }
      .Info {
        p {
          @media only screen and (max-width: 480px) {
            line-height: 100%;
            margin: 3% 0;
          }
          color: #fff;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          margin: 0;
        }
      }
      .Footer {
        @media only screen and (max-width: 480px) {
          justify-content: space-around;
        }
        display: grid;
        grid-template-columns: 40% 10% 40%;
        .buttonClick {
          @media only screen and (max-width: 480px) {
            width: 100%;
          }
          display: flex;
          padding: 5px 0;
          width: 90%;
          cursor: pointer;
          text-align: center;
          background-color: #f89000;
          text-decoration: none;
          border-radius: 40px;
          align-items: center;
          justify-content: center;
          border: none;
          transition: all ease 0.5s;
          a {
            font-size: 15px;
            letter-spacing: 0px;
            font-weight: bold;
            color: #004291;
            margin: 0;
          }
        }
        .buttonClick:hover {
          background-color: #ff741d;
          a:hover {
            color: #fff;
          }
        }
        p {
          @media only screen and (max-width: 480px) {
            align-items: center;
          }
          color: #fff;
          display: flex;
          opacity: 1;
          text-decoration: none;
          font-size: 16px;
          letter-spacing: 0px;
          position: relative;
          font-weight: 400;
          margin: 0;
        }
        .buttonWhats {
          @media only screen and (max-width: 480px) {
            width: 100%;
          }
          display: flex;
          padding: 5px 0;
          width: 90%;
          cursor: pointer;
          text-align: center;
          background-color: #4caf50;
          text-decoration: none;
          border-radius: 40px;
          align-items: center;
          justify-content: center;
          border: none;
          transition: all ease 0.5s;
          .FaWhatsapp {
            @media only screen and (max-width: 480px) {
              margin-left: 10%;
            }
            font-size: 150%;
            margin-right: 3%;
            path {
              fill: #fff;
              transition: all ease 0.5s;
            }
          }
          a {
            font-size: 15px;
            letter-spacing: 0px;
            font-weight: bold;
            color: #fff;
            margin: 0;
            transition: all ease 0.5s;
          }
        }
        .buttonWhats:hover {
          background-color: #318935;
          .FaWhatsapp {
            path {
              fill: #004291;
            }
          }
          a:hover {
            color: #004291;
          }
        }
      }
    }
  }
`;

export const Mail = styled.div`
  .Text {
    @media only screen and (max-width: 480px) {
      padding-left: 5%;
    }
    margin-bottom: 50px;
    padding-left: 15%;
    .headerImg {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      .GoMail {
        font-size: 300%;
        margin-right: 2%;
        path {
          fill: #f89000;
        }
      }
      .headerList {
        @media only screen and (max-width: 480px) {
          font-size: 40px;
        }
        color: #004291;
        display: flex;
        opacity: 1;
        text-decoration: none;
        font-size: 40px;
        letter-spacing: 0px;
        position: relative;
        font-weight: bold;
        margin: 0;
        line-height: 100%;
      }
    }
    .Line {
      @media only screen and (max-width: 480px) {
        margin-left: 15%;
      }
      margin: 10px 180px;
      width: 60px;
      height: 8px;
      background-color: #f89000;
      border-radius: 100px;
    }
  }
  .boxForm {
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
    height: auto;
    form {
      display: flex;
      position: relative;
      flex-flow: wrap;
      justify-content: space-between;
      .inputForm {
        width: 30%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        input {
          width: 100%;
          border-color: #707070 !important;
          border-radius: 65px;
          padding: 5px 15px;
          border-style: solid;
          border-width: 0.5px;
          color: #575757;
          font-size: 16px;
        }
        span {
          width: 100%;
          font-size: 14px;
          color: red;
          height: 20px;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .inputText {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        width: 100%;
        textarea {
          width: 100%;
          border-color: #707070 !important;
          border-radius: 15px;
          padding: 5px 15px;
          border-style: solid;
          border-width: 0.5px;
          color: #575757;
          font-size: 16px;
          resize: none;
          height: 100px;
        }
        span {
          font-size: 14px;
          color: red;
        }
      }

      .btn {
        width: 30%;
        margin: auto;
        button {
          display: flex;
          padding: 7px 0;
          width: 100%;
          margin: unset;
          cursor: pointer;
          text-align: center;
          background-color: #f89000;
          text-decoration: none;
          border-radius: 40px;
          align-items: center;
          justify-content: center;
          border: none;
          transition: all ease 0.5s;
          font-size: 15px;
          letter-spacing: 0px;
          font-weight: bold;
          color: #ffffff;
        }
        button:hover {
          background-color: #ff741d;
        }

        button:active {
          background-color: #ff741d;
          box-shadow: 0 5px #666;
          transform: translateY(4px);
        }
      }
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
  }
`;
