import React, { useState } from 'react';
// imports de dependencias
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
// import { notification } from 'antd';
import * as Yup from 'yup';
// import ademail from '../../services/ademail';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';

import { Content } from './styles';

// imports para estilização
import { FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';
import { GrClock } from 'react-icons/gr';
import { GrLocation } from 'react-icons/gr';

export default function TrabalheConosco() {

  const [disabledButton] = useState(false);
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      nome : '',
      email : '',
      telefone : '',
      funcao : '',
      cidade : '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('* O campo nome é obrigatório.'),
      email: Yup.string()
        .email('Informe um e-mail válido.')
        .required('* O campo email é obrigatório.'),
      telefone: Yup.string().required('* O campo telefone é obrigatório.'),
      funcao: Yup.string().required('* O campo função é obrigatório.'),
      cidade: Yup.string().required('* O campo cidade é obrigatório.'),
    }),

    onSubmit: async (values, { resetForm }) => {
      // setdisabledButton(true);
      //   // Sucesso ao enviar
      //   setdisabledButton(false);
      //   resetForm();

      // }, (error) => {
      //   console.log(error.text);

      //   // Erro ao enviar
      //   setdisabledButton(false);
      //   notification.error({
      //     message: 'Não foi possivel concluir o contato! 😔',
      //     description: 'Verfique os campos e tente novamente mais tarde...',
      //     placement: 'bottomRight',
      //   });
      // });

      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Função: ${values.funcao}</p>
        <p>Cidade: ${values.cidade}</p>
      `;
      
      var dataUri = null;
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function () {
        dataUri = "data:" + file.type + ";base64," + btoa(reader.result);

        window.Email.send({
          SecureToken : "9efef40a-d64b-49ff-9dfc-0781e35df3e2",
          To : 'marketing@prmempreendimentos.com.br',
          From : "contato@prmempreendimentos.com.br",
          Subject : "Novo currículo enviado via site!",
          Body : body,
          Attachments : [{
            name : file.name,
            data : dataUri
          }]
        }).then(
          message => alert('Currículo enviado com sucesso!')
        );
      };
      reader.onerror = function() {
        console.log('there are some problems');
      }
    },
  });
  return (
    <>
      <Helmet>
        <title>PRM - Empreendimentos</title>
      </Helmet>
      <Header />
      <Content>
        <div className="banner">
          <img
            src={require('../../assets/img/trabalheConosco.jpeg')}
            alt="bannerSobre"
          />
        </div>
        <div className="Text">
          <p className="headerList">Trabalhe Conosco</p>
          <div className="Line" />
        </div>
        <div className="boxForm">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <input
                type="text"
                name="nome"
                placeholder="Nome completo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome ? (
                <span className="erro">{formik.errors.nome}</span>
              ) : null}
            </div>
            
            <div>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <span className="erro">{formik.errors.email}</span>
              ) : null}
            </div>
            
            <div>
              <input
                type="tel"
                name="telefone"
                mask={
                  formik.values.telefone.length <= 14
                    ? '(99) 9999-9999?'
                    : '(99) 99999-9999'
                }
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                maskChar={null}
                placeholder="Telefone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.telefone}
              />
              {formik.touched.telefone && formik.errors.telefone ? (
                <span>{formik.errors.telefone}</span>
              ) : null}
            </div>

            <div>
              <input
                type="text"
                name="funcao"
                placeholder="Função desejada"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.funcao}
              />
              {formik.touched.funcao && formik.errors.funcao ? (
                <span className="erro">{formik.errors.funcao}</span>
              ) : null}
            </div>

            <div>
              <input
                type="text"
                name="cidade"
                placeholder="Cidade"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cidade}
              />
              {formik.touched.cidade && formik.errors.cidade ? (
                <span className="erro">{formik.errors.cidade}</span>
              ) : null}
            </div>
            
            <div>
              <input
                id="curriculo"
                name="curriculo"
                type="file" 
                onChange={(event) => {
                  setFile(event.currentTarget.files[0]);
                }}/>
            </div>
            
            <div className="btn">
              <button type="submit" className={disabledButton ? 'enable' : ''}  disabled={disabledButton ? 'disabled' : ''} >ENVIAR</button>
            </div>
          </form>
        </div>
        <div className="lineOrange" />
        <div className="boxBlue">
          <div className="icons">
            <FiPhone className="FiPhone" />
          </div>
          <div className="icons">
            <GoMail className="GoMail" />
          </div>
          <div className="icons">
            <GrClock className="GrClock" />
          </div>
          <div className="icons">
            <GrLocation className="GrLocation" />
          </div>
          <div className="tel">
            <a href="tel: +554135324224">(41) 3532.4224</a>
            <a href="tel: +554133282442">(41) 3328.2442</a>
          </div>
          <div className="email">
            <a href="mailto: contato@prmempreendimentos.com.br">
              contato@prmempreendimentos.com.br
            </a>
          </div>
          <div className="hour">
            <p>Seg. a Sex. das 8h às 18h</p>
          </div>
          <div className="location">
            <p>Rua Dep. Nilson Ribas Nº 845 | Seminário Curitiba | PR</p>
          </div>
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
          <div className="smallLineOrange" />
        </div>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.767191122587!2d-49.31254798498541!3d-25.446045783780995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3b8d8e9aa57%3A0xc2eb0540c748a762!2sR.%20Dep.%20Nilson%20Ribas%2C%20845%20-%20Semin%C3%A1rio%2C%20Curitiba%20-%20PR%2C%2080740-510!5e0!3m2!1spt-BR!2sbr!4v1600886090688!5m2!1spt-BR!2sbr"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </Content>
      {/* <Youtube /> */}
      <Aluguel />
      <Footer />
    </>
  );
}
